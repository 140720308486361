import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=5a877aea&"
import script from "./Search.vue?vue&type=script&lang=js&"
export * from "./Search.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputSearch: require('/usr/src/set-nuxt/components/Search/InputSearch.vue').default,PopularKeyword: require('/usr/src/set-nuxt/components/Search/PopularKeyword.vue').default,PopularKeyContent: require('/usr/src/set-nuxt/components/Search/PopularKeyContent.vue').default})
