//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { elementObserver, elementAttributeObserver } from '@/helpers/element-observer'
export default {
  name: 'ChatBotZwizPersonalizedPopup',
  data () {
    const cookiesName = 'bull-popup-hidden'
    const bullPopupHidden = this.$cookies.get(cookiesName)
    return {
      cookiesName,
      popupPosition: {
        bottom: 20
      },
      bullPopupHidden
    }
  },
  computed: {
    chatTitle () {
      const date = new Date()
      if (this.permissionUser()) {
        const fullName = this.$store.state.profile.user.firstName + ' ' + this.$store.state.profile.user.lastName
        return 'สวัสดีคุณ' + ' ' + fullName
      }
      return 'สวัสดี วัน' + this.$moment(date).format('ddd')
    }
  },
  mounted () {
    elementObserver({
      observeConfig: { childList: true, subtree: true },
      parent: document.body,
      nodeId: 'zwiz-web-chat'
    }, () => {
      this.updatePosition()
    })
    elementAttributeObserver({
      observeConfig: { attributeFilter: ['class'] },
      el: document.getElementById('popup-outer-box')
    }, () => {
      this.bullPopupHidden = this.$cookies.get(this.cookiesName)
    })
  },
  methods: {
    updatePosition () {
      const container = document.querySelector('.chat-layout')
      if (!container) { return }
      const zwizWebChat = document.getElementById('zwiz-web-chat')
      const { offsetTop: zwizWebChatTop } = zwizWebChat || { offsetTop: 0 }
      const bottom = window.innerHeight - zwizWebChatTop
      container.style.bottom = bottom + 'px'
      this.popupPosition.bottom = bottom + 'px'
    },
    closeChatMedia () {
      const chatIcon = document.getElementById('chatbot-bull-icon')
      chatIcon.style.display = 'none'
      this.$refs.popup.style.display = 'none'
    }
  }
}
