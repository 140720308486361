//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
import { element } from '~/resources'
export default {
  name: 'Pdpa',
  data () {
    return {
      pDPACookie: null,
      versionName: '',
      element: element.pdpa,
      show: false
    }
  },
  async created () {
    this.removeDuplicateCookie()
    await this.setPdpaData()
    this.versionName = this.pDPACookie.versionName ? `PDPA Version ${this.pDPACookie.versionName}` : ''
    this.show = this.pDPACookie.isActive
    if (this.pDPACookie && this.pDPACookie.version.toString() === this.$cookies.get('SET_COOKIE_POLICY').toString()) {
      this.show = false // this.pDPACookie.isActive = false
    }
  },
  methods: {
    setPdpaData () {
      if (this.$store.state.contents.content) {
        this.pDPACookie = cloneDeep(this.$store.state.contents.content.pDPACookie)
      }
    },
    async accecptPDPA () {
      await this.handleClickTracking()
      this.show = false // this.pDPACookie.isActive = false
      this.$cookies.set('SET_COOKIE_POLICY', this.pDPACookie.version, { path: '/', expires: new Date(Number(new Date()) + 30 * 1000 * 60 * 60 * 24) })
    },
    handleClickTracking () {
      const dataAction = this.versionName // Data Action(Section)
      this.clickTracking({
        element: this.element,
        title: this.pDPACookie.btn,
        sectionName: dataAction,
        teamName: this.pDPACookie.team_name
      })
    },
    // removeDuplicateCookie () {
    //   // remove by path
    //   this.$cookies.remove('SET_COOKIE_POLICY', {
    //     path: this.$route.path
    //   })
    // },
    removeDuplicateCookie () {
      // remove by path
      this.$cookies.remove('SET_COOKIE_POLICY', {
        path: '/th'
      })
      // remove by path
      this.$cookies.remove('SET_COOKIE_POLICY', {
        path: '/en'
      })
      // remove by path
      this.$cookies.remove('SET_COOKIE_POLICY', {
        path: this.$route.path
      })
      // remove by fullpath
      this.$cookies.remove('SET_COOKIE_POLICY', {
        path: this.$route.fullPath
      })
    }
  }
}
