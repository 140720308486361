/* eslint-disable */
import { spRouteNamePattern, element } from '~/resources'
const convertToRealUndefined = (data) => {
  if (typeof data === 'string' && data === 'Undefined') {
    return undefined
  }
  return data
}
const isKnowledge = (routeName) => {
  const isknowledge = spRouteNamePattern.knowledge.test(routeName) ||
  spRouteNamePattern.knowledgeArticle.test(routeName) ||
  spRouteNamePattern.knowledgeVideo.test(routeName) ||
  spRouteNamePattern.knowledgeEBook.test(routeName) ||
  spRouteNamePattern.knowledgeElearning.test(routeName) ||
  spRouteNamePattern.knowledgePodcast.test(routeName)
  return isknowledge
}
/**
 * Welcome Page, Glossary, Follow Group, Playlist(video, podcast)
 */
const isSpecificPageByContent = (route) => {
  const routeName = route.name
  const query = route.query || {}
  const knowledgePage = isKnowledge(routeName)

  let specificPage = spRouteNamePattern.WelcomePage.test(routeName) ||
  spRouteNamePattern.home.test(routeName) || knowledgePage ||
  spRouteNamePattern.search.test(routeName) ||
  spRouteNamePattern.profile.test(routeName) ||
  spRouteNamePattern.knowledgeVideoPlayList.test(routeName) ||
  spRouteNamePattern.knowledgePodcastPlayList.test(routeName) ||
  spRouteNamePattern.glossary.test(routeName)

  if (spRouteNamePattern.profile.test(routeName) && query.type && query.type.toLowerCase() === 'follow') {
    specificPage = true
  }
  return specificPage
}
/**
 * Home, Search, Knowledge, Profile
 */
const isSpecificPage = (route) => {
  const routeName = route.name
  const query = route.query || {}
  const knowledgePage = isKnowledge(routeName)

  let specificPage = spRouteNamePattern.home.test(routeName) || knowledgePage ||
  spRouteNamePattern.search.test(routeName) ||
  spRouteNamePattern.profile.test(routeName) ||
  spRouteNamePattern.knowledgeVideoPlayList.test(routeName) ||
  spRouteNamePattern.knowledgePodcastPlayList.test(routeName) ||
  spRouteNamePattern.glossary.test(routeName)

  if (spRouteNamePattern.profile.test(routeName) && query.type && query.type.toLowerCase() === 'follow') {
    specificPage = true
  }
  return specificPage
}
const specificPageCategory = (route) => {
  const routeName = route.name
  const query = route.query || {}
  const knowledgePage = isKnowledge(routeName)
  if (spRouteNamePattern.WelcomePage.test(routeName)) {
    return ['Welcome Page']
  }
  if (spRouteNamePattern.home.test(routeName)) {
    return ['Home']
  }
  if (knowledgePage) {
    return ['Knowledge']
  }
  if (spRouteNamePattern.knowledgeVideoPlayList.test(routeName)) {
    return ['Video Playlist']
  }
  if (spRouteNamePattern.knowledgePodcastPlayList.test(routeName)) {
    return ['Podcast Playlist']
  }
  if (spRouteNamePattern.glossary.test(routeName)) {
    return ['Glossary']
  }
  // if (spRouteNamePattern.glossaryDetail.test(routeName)) {
  //   return ['Glossary Detail']
  // }
  if (spRouteNamePattern.search.test(routeName)) {
    return ['Search Result']
  }
  if (spRouteNamePattern.profile.test(routeName)) {
    const type = query.type
    if (type && type.toLowerCase() === 'follow') {
      return ['Profile Follow']
    }
    return ['Profile Bookmark']
  }
  return []
}
const specificPageContentGroup = (route) => {
  const { name } = route
  if (isSpecificPageByContent(route)) {
    
    // console.log(route)
    if (spRouteNamePattern.glossary.test(name)) {
      return 'Glossary'
    }else if (spRouteNamePattern.WelcomePage.test(name)){
      return "Welcome Page"
    }
    return 'Others'
  }else
  if(spRouteNamePattern.error404.test(name)){
    return 'error'
  }else return ''
}
const specificPageContentTag = (route, i18n) => {
  const { name: routeName } = route
  if (spRouteNamePattern.WelcomePage.test(routeName)) {
    return i18n.t('pages.welcomePage.keywords').split(',').map(k => k.trim())
  }
  return null
}
export default (context, inject) => {
  const { app, store, route } = context
  let curentRoute = route
  app.router.afterEach((to, from) => {
    curentRoute = to
  })

  /*
  ** Get tracking id
  */
  // const content = store.state.contents.content || {}
  // let gtmTrackingId = ''
  // if (content.advanced) {
  //   gtmTrackingId = content.advanced.gtmTrackingId
  // }
  // if (gtmTrackingId) {
  //   ;(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer', gtmTrackingId)
  // }

  /*
  ** Inject plugin
  */
  const pluginObject = {
    /**
     * event click
     * data-trigger="event-tracked-element"
     * data-category=" 'tsi - setinvestnow – <ชื่อหน้า เช่น Home, คลังความรู้, search>"
     * data-action="click <ชื่อ section> ถ้าไม่มี sectionจะเป็น click content "
     * data-label=" '<content group> – <content category> - <page title/title ของ อีบุ๊ค หรือ clip> ">
     */

    /**
     * Event click
     * @param {*} opt
     * opt{contentGroup, categoryName, sectionName, title, pageTitle}
     */
    onClick (opt) {
      const dataContent = store.state.contents.content || {}
      const contentGroup = specificPageContentGroup(curentRoute) || (dataContent.gtmDataLayer ? (dataContent.gtmDataLayer.content_group || '') : '')
      const specificPageName = specificPageCategory(curentRoute)
      const categoryName = (dataContent.gtmDataLayer && convertToRealUndefined(dataContent.gtmDataLayer.product)) ? (dataContent.gtmDataLayer.product || '') : specificPageName.join('')
      const pageName = opt.pageName || dataContent.title
      const title = opt.title || dataContent.title // use content title for bookmark content
      const label = []
      if (convertToRealUndefined(contentGroup)) {
        label.push(contentGroup)
      }
      if (categoryName) {
        label.push(categoryName)
      }
      if (title) {
        label.push(title)
      }

      // const teamName = opt.teamName || process.env.GTM_EVENT_CATEGORY_TEAM
      let teamName =  (dataContent.gtmDataLayer || {}).team_name || ''
      if (isSpecificPage(curentRoute)) {
        teamName = process.env.GTM_EVENT_CATEGORY_TEAM
      }
      // set team by element
      if (opt.element === element.pdpa) {
        teamName = dataContent.pDPACookie.team_name
      }
      const websiteName = (dataContent.websiteInfo || { websiteName: dataContent.title /* get title from welcomepage */ }).websiteName
      let evtCategory = []
      if (teamName) {
        evtCategory.push(teamName)
      }
      if (websiteName) {
        evtCategory.push(websiteName)
      }
      if (pageName) {
        evtCategory.push(pageName)
      }
      const eventData = {
        event: 'event-tracked-element',
        eventCategory: evtCategory.join(' - '),
        eventAction: `click ${opt.sectionName || 'content'}`,
        eventLabel: label.join(' - ')
      }
      // GTM push data
      if (typeof(dataLayer) !== "undefined") {
        dataLayer.push(eventData)
      }
    },

    /**
     * Click search
     */
    pushDataLayerSiteSearch (keyword, setSearchBoxID) {
      const textKeyword = keyword || ''
      if (!textKeyword) { return }
      if (typeof(dataLayer) !== "undefined") {
        window.dataLayer.push({
          event: 'set_site_search',
          setSearchBoxID,
          setSearchTerm: textKeyword
        })
      }
    },

    /**
     * Pageview
     * @param {*} opt
     * opt{title url}
     */
    pageView (opt) {
      const eventData = {
        event: 'pageview',
        page: {
          path: opt.url,
          title: opt.title
        }
      }
      if (typeof(dataLayer) !== "undefined") {
        dataLayer.push(eventData)
      }
    },
    /**
    * Big data by page personalized 😉
    * customRoute ใช้สำหรับบางหน้าที่ใช้ window.history
    */
    setContentType (route) {
      if(spRouteNamePattern.WelcomePage.test(route.name)){
        return 'Dynamic'
      }
      // else if (spRouteNamePattern.error404.test(route.name)) {
      //   return 'error'
      // }
      else return undefined
    },
    pagePersonalized(customRoute) {
      const r = customRoute || curentRoute
      const dataContent = store.state.contents.content || {}
      const contentGroup = specificPageContentGroup(r)
      const contentCategory = specificPageCategory(r)
      const contentType = this.setContentType(r)
      // Select content tag
      let contentTag = specificPageContentTag(r, app.i18n) || (dataContent.keywords ? dataContent.keywords.split(',') : [])
      // End select content tag

      let eventData = {
        content_type: contentType,
        content_tag: contentTag,
        content_group: contentGroup,
        content_category: contentCategory,
        product_name: undefined,
        activity_type: undefined,
        activity_focus: undefined
      }
      if (dataContent.gtmDataLayer) {
        const hasContentCategory = (dataContent.gtmDataLayer.content_category || []).length > 0
        const hasContentTag = (dataContent.gtmDataLayer.content_tag || []).length > 0
        eventData = {
          content_type: contentType || convertToRealUndefined(dataContent.gtmDataLayer.content_type),
          content_tag: hasContentTag ? dataContent.gtmDataLayer.content_tag : contentTag,
          content_group: contentGroup || dataContent.gtmDataLayer.content_group || 'Undefined',
          content_category: hasContentCategory ? dataContent.gtmDataLayer.content_category : contentCategory,
          product_name: convertToRealUndefined(dataContent.gtmDataLayer.product),
          activity_type: convertToRealUndefined(dataContent.gtmDataLayer.activity_type || 'Undefined'),
          activity_focus: convertToRealUndefined(dataContent.gtmDataLayer.activity_focus || 'Undefined')
        }
      }
      if (typeof(dataLayer) !== "undefined") {
        dataLayer.push(eventData)
        const { fullPath } = curentRoute
        store.commit('setContentDataLayerCurrentPath', fullPath)
      }
    },
    /**
    * Big data by content personalized 😉
    * Action: click on content card
    */
    contentPersonalized(gtmDataLayer) {
      if (gtmDataLayer) {
        const contentGroup = specificPageContentGroup(curentRoute)
        const contentCategory = specificPageCategory(curentRoute)
        const hasContentCategory = (gtmDataLayer.content_category || []).length > 0
        const eventData = {
          content_type: convertToRealUndefined(gtmDataLayer.content_type),
          content_tag: gtmDataLayer.content_tag, // tag ที่มาจาก ebook หรือ e-learning เท่านั้น
          content_group: contentGroup || gtmDataLayer.content_group || 'Undefined',
          content_category: hasContentCategory ? gtmDataLayer.content_category : contentCategory,
          product_name: convertToRealUndefined(gtmDataLayer.product),
          activity_type: convertToRealUndefined(gtmDataLayer.activity_type || 'Undefined'),
          activity_focus: convertToRealUndefined(gtmDataLayer.activity_focus || 'Undefined')
        }
        if (typeof(dataLayer) !== "undefined") {
          dataLayer.push(eventData)
        }
      }
    }
  }
  inject('gtmTracking', pluginObject)
  context.$gtmTracking = pluginObject
}
