//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      pathLogin: this.$authUser.login(),
      pathRegister: process.env.SET_REGISTER_URL,
      isStick: false,
      showMenuMobile: false
    }
  },
  computed: {
    menuName () {
      return this.getContentMenu('Header Menu').menuName || ''
    },
    menuList () {
      return this.getContentMenu('Header Menu').menuItems || []
    },
    content () {
      return this.$store.state.contents.content || {}
    },
    websiteInfo () {
      return this.content.websiteInfo || {}
    }
  },
  async mounted () {
    this.registerEvent()
    await this.main()
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    setUrl () {
      this.$cookies.set('url', window.location.href)
    },
    getNavbarHeight () {
      return document.getElementById('navbar').clientHeight || 0
    },
    getNavbarTop () {
      const top = document.getElementById('navbar').offsetTop
      return top
    },
    getScrollTop () {
      return window.pageYOffset || document.documentElement.scrollTop
    },
    updateBodyPaddingTop (paddingTop) {
      const site = document.querySelector('.site-content')
      site.style.paddingTop = `${paddingTop}px`
    },
    main () {
      this.lastScrollTop = this.getNavbarTop()
      this.handleScroll()
    },
    handleScroll () {
      const marketSummary = document.querySelector('.market-summary')
      const smartbanner = document.querySelector('.smartbanner')
      let lastScrollTop = 0
      if (marketSummary) {
        lastScrollTop += marketSummary.clientHeight
      }
      if (smartbanner) {
        lastScrollTop += smartbanner.clientHeight
      }
      this.lastScrollTop = lastScrollTop
      // update sticky
      const currentScrollTop = this.getScrollTop()
      const isScrollDown = currentScrollTop > this.lastScrollTop
      this.isStick = isScrollDown
      const navbarHeight = isScrollDown ? this.getNavbarHeight() : 0
      this.updateBodyPaddingTop(navbarHeight)
    },
    registerEvent () {
      window.addEventListener('scroll', this.handleScroll)
    },
    removeEvent () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    toggleMenuMobile () {
      this.showMenuMobile = !this.showMenuMobile
      if (this.showMenuMobile) {
        document.body.classList.add('menu-open')
      } else {
        setTimeout(() => {
          document.body.classList.remove('menu-open')
        }, 200)
      }
    }
  }
}
