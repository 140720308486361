const actions = {
  /**
   *
   * @param {*} payload
   * {
   *  quizChoiceUuid: '',
   *  answer: 4
   * }
   *
   */
  async submitAnswer ({ commit }, quiz) {
    const response = await this.$axios
      .post(process.env.API_ENDPOINT + 'quizChoice/submit', quiz)
      .then((res) => {
        return res
      })
      .catch(err => err)
    return response
  }
}

export default {
  namespaced: true,
  actions
}
