const state = () => ({
  videos_playlist_summary: {},
  video_playlist_detail: {}
})

const mutations = {
  setVideosPlaylistSummary (state, data) {
    state.videos_playlist_summary = data
  },
  setVideoPlaylistDetail (state, data) {
    state.video_playlist_detail = data
  }
}

const actions = {
  /*
   ** get data videos playlist summary
   */
  async getVideosPlaylistSummary ({ commit }, payload) {
    payload = payload || {}
    await this.$axios
      .get(process.env.API_ENDPOINT + 'video-playlist/summary', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageIndex: payload.pageIndex || 0,
          pageSize: payload.pageSize || 9
        }
      })
      .then(res => commit('setVideosPlaylistSummary', res ? res.data : {}))
      .catch(err => err)
  },
  /*
  ** get data videos playlist detail
  */
  async getVideoPlaylistDetail ({ commit }, payload) {
    let url = process.env.API_ENDPOINT + 'video-playlist/videos'
    if (payload.mode === 'preview') {
      url = process.env.API_ENDPOINT + 'video-playlist/videos-preview'
    }
    await this.$axios
      .get(url, {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageUuid: payload.pageUuid,
          pageIndex: payload.pageIndex,
          pageSize: payload.pageSize
        }
      })
      .then(res => commit('setVideoPlaylistDetail', res.data))
      .catch(err => err)
  }
}
const getters = {
  getVideosPlaylistSummaryData: (state) => {
    return state.videos_playlist_summary
  },
  getVideoPlaylistDetailData: (state) => {
    return state.video_playlist_detail
  }
}

export default {
  namespace: true,
  state,
  mutations,
  actions,
  getters
}
