import Vue from 'vue'
Vue.mixin({
  validate ({ app, redirect }) {
    const ua = app.$ua
    if (
      (ua.browser() === 'Chrome' &&
        parseInt(ua.browserVersion()) < 80) ||
        (ua.browser() === 'Firefox' &&
        parseInt(ua.browserVersion()) < 75) ||
        (ua.browser() === 'Safari' &&
        parseInt(ua.browserVersion()) < 13) ||
        (ua.browser() === 'Edge' &&
        parseInt(ua.browserVersion()) < 18) ||
        (ua.browser() === 'Opera' && parseInt(ua.browserVersion()) < 30)
    ) {
      redirect(`/${app.i18n.locale}/error/oldversion`)
    }
    return true
  }
})
