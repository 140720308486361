
import isFunctionalPage from '~/helpers/functionalPage'
const isInvalidPath = (path) => {
  const splitPath = path.split('/')
  let inValid = false
  if (splitPath.length >= 3) {
    inValid = /^\?/i.test(splitPath[2]) || !splitPath[2]
  }
  return inValid
}
export default async function ({
  app,
  store,
  route,
  redirect,
  req
}) {
  const invalidPath = await isInvalidPath(route.fullPath)
  if (invalidPath === true) {
    redirect('/')
  }

  // Some pages do not require content data
  const _isFunctionalPage = await isFunctionalPage(route.name)
  if (_isFunctionalPage) { return }

  // Set client id
  await app.$helpers.setClientUuid()

  if (process.server) {
    // Set activityLog data ****
    const activityLogData = {
      ipAddress: req?.connection.remoteAddress || req?.socket.remoteAddress,
      Browser: app.$ua.browser(),
      BrowserVersion: app.$ua.browserVersion(),
      ChannelCode: process.env.WEB_CHANNEL
    }
    store.commit('profile/setActivityLogData', activityLogData)
  }

  // Get content data
  await store.dispatch('contents/getContent', route)
}
