// https://dev.to/alejomartinez8/how-to-load-a-hash-fragment-to-an-anchor-name-in-react-especially-in-first-loading-g3i
export default function () {
  const { scrollVerticalToElementById } = require('@/helpers/scrollToElementOnPage')
  const getHash = () => {
    const hash = window.location.hash
    return hash?.replace('#', '')
  }
  const elementBoundingClientRectById = (id) => {
    const element = document.getElementById(id)
    const rectangle = element?.getBoundingClientRect()
    return rectangle
  }
  const elementBoundingClientRectByElement = (element) => {
    const rectangle = element?.getBoundingClientRect()
    return rectangle
  }
  const scrollToElementByHash = () => {
    const elementBoundingClientRect = elementBoundingClientRectById('navbar')
    const padding = elementBoundingClientRect?.height || 0
    scrollVerticalToElementById(getHash(), padding)
  }
  // const triggerTab = () => {
  //   const element = document.getElementById(getHash())
  //   const containerTab = element?.closest('[data-element="container_tab"]') ?? {}
  //   const containerTabId = containerTab.id
  //   const triggerEl = document.querySelector(`[data-bs-toggle="tab"][aria-controls="${containerTabId}"]`)
  //   if (triggerEl) {
  //     const { Tab } = require('bootstrap')
  //     const tab = Tab.getOrCreateInstance(triggerEl)
  //     tab.show()
  //   }
  //   setTimeout(() => { scrollToElementByHash() }, 200) // Do after tab animation complete.
  // }

  // Images load
  const handleImagesLoaded = (images) => {
    const isComplate = images.map(image => image.complete).every(item => item === true)
    if (isComplate) {
      scrollToElementByHash()
    }
  }
  const imagesLoaded = async () => {
    const hash = window.location.hash
    if (!hash) { return false }
    const imageSelector = [
      '.site-content picture img',
      '[data-element="element_text_editor"] img',
      '[data-element="element_html"] img'
    ]
    const images = await [].slice.call(document.querySelectorAll(imageSelector.join(',')))
    const imageAfterFilter = images.filter((img) => {
      const { height, width } = elementBoundingClientRectByElement(img)
      return height > 0 && width > 0
    })
    if (imageAfterFilter.length === 0) {
      scrollToElementByHash()
      return false
    }
    imageAfterFilter.forEach((image) => {
      const { src } = image.dataset
      if (src) {
        delete image.dataset.src
        image.src = src
      } else {
        handleImagesLoaded(imageAfterFilter)
      }
      image.addEventListener('load', (event) => {
        handleImagesLoaded(imageAfterFilter)
      }, {
        once: true
      })
      image.addEventListener('error', (event) => {
        handleImagesLoaded(imageAfterFilter)
      }, {
        once: true
      })
    })
  }

  const handleHashchange = () => {
    imagesLoaded()
    // triggerTab()
  }
  const handleDOMContentLoaded = () => {
    imagesLoaded()
    // triggerTab()
  }
  const handleWindowLoaded = () => {
    imagesLoaded()
    // triggerTab()
  }

  window.addEventListener('hashchange', handleHashchange)
  window.addEventListener('popstate', handleHashchange)
  window.addEventListener('load', handleWindowLoaded)
  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded)
}
