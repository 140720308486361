//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModalUnFollow',
  methods: {
    submitFollow () {
      this.$bvModal.hide('modal-unfollower')
      this.$store.commit('handle/setActive', false)
      this.handleFollow(this.$store.state.handle.handle.follow)
    }
  }
}
