//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { spRouteNamePattern } from '~/resources'
export default {
  name: 'FooterBanner',
  props: {
    pageType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isShow: false
    }
  },
  computed: {
    ...mapGetters({ footerBanner: 'banner/getFooterBannerData' }),
    bannerDesktopClass () {
      return this.footerBanner.desktopPersonalized
    },
    bannerMobileClass () {
      return this.footerBanner.mobilePersonalized
    }
  },
  watch: {
    '$route.fullPath': {
      immediate: true,
      handler (value) {
        this.checkRenderFooterBanner()
      }
    }
  },
  async mounted () {
    await this.getFooterBanner({ bannerUuid: process.env.FOOTER_BANNER_UUID })
    this.checkRenderFooterBanner()
  },
  methods: {
    ...mapActions('banner', ['getFooterBanner']),
    checkRenderFooterBanner () {
      const routeName = this.$route.name
      const knowledgePage = spRouteNamePattern.knowledge.test(routeName) ||
      spRouteNamePattern.knowledgeArticle.test(routeName) ||
      spRouteNamePattern.knowledgeVideo.test(routeName) ||
      spRouteNamePattern.knowledgeEBook.test(routeName) ||
      spRouteNamePattern.knowledgeElearning.test(routeName) ||
      spRouteNamePattern.knowledgePodcast.test(routeName)
      if (spRouteNamePattern.search.test(routeName)) {
        this.isShow = true
      } else if (spRouteNamePattern.profile.test(routeName)) {
        this.isShow = true
      } else if (this.pageType === 'ContentCategory' || this.pageType === 'FollowGroup') {
        this.isShow = true
      } else if (knowledgePage) {
        this.isShow = true
      } else if (spRouteNamePattern.knowledgeVideoPlayList.test(routeName) || spRouteNamePattern.knowledgeVideoPlayListDetail.test(routeName)) {
        this.isShow = true
      } else if (spRouteNamePattern.knowledgeVideoDetail.test(routeName)) {
        this.isShow = true
      } else if (spRouteNamePattern.knowledgePodcastPlayList.test(routeName) ||
                 spRouteNamePattern.knowledgePodcastPlayListDetail.test(routeName) ||
                 spRouteNamePattern.knowledgePodcastDetail.test(routeName)
      ) {
        this.isShow = true
      } else if (spRouteNamePattern.glossary.test(routeName) || spRouteNamePattern.glossaryDetail.test(routeName)) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    }
  }
}
