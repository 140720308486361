/**
 * Initial plugin
 */
export default function ({ $axios, app, route }, inject) {
  /**
   * Set instance
   */
  // const apiKey = process.env.API_CMS_KEY
  const webChannel = process.env.WEB_CHANNEL
  const requestTime = new Date().getTime()
  const baseURL = `${process.env.API_ENDPOINT}v1/`
  const instanceOption = {
    baseURL,
    meta: {
      requestStartedAt: requestTime
    },
    headers: {
      common: {
        // 'x-api-key': apiKey,
        'x-channel': webChannel
      }
    }
  }

  // Create a custom axios instance
  const axiosInstance = $axios.create(instanceOption)
  axiosInstance.interceptors.request.use((config) => {
    if (process.server) {
      console.log('[INFO]', 'Page URL:', route.fullPath)
      console.log('[INFO]', '[Request]API CMS - path:', config.url)
      console.log('[INFO]', '[Request]API CMS - params:', config.params || '-')
      console.log('[INFO]', '[Request]API CMS - endpoint:', config.baseURL)
      console.log('=================================================')
    }
    // Server to server api cms
    const meta = {
      requestStartedAt: new Date().getTime()
    }
    config.meta = meta
    const clientId = app.store.state.utility.clientUuid || ''
    if (clientId) {
      config.headers['x-client-uuid'] = clientId
    }

    // request page uuid
    const { pageUuid } = app.store.state.contents.content || {}
    if (pageUuid) {
      config.headers['x-page-uuid'] = (pageUuid || '')
    }
    return config
  }, (x) => {
    if (process.server) {
      console.error('Page URL:', route.fullPath)
      console.error('[Request]API CMS - Error details:', x)
      console.log('=================================================')
    }
    // console.log('[Request]API CMS - Headers:', x.config.headers)
    throw x
  })

  axiosInstance.interceptors.response.use((x) => {
    if (process.server) {
      // console.log('[Response]API CMS - Headers:', x.config.headers)
      console.log('[INFO]', 'Page URL:', route.fullPath)
      console.log('[INFO]', '[Response]API CMS - status:', x.status)
      console.log('[INFO]', '[Response]API CMS - endpoint:', x.config.baseURL)
      console.log('[INFO]', '[Response]API CMS - headers:', x.config.headers)
      // console.log('[INFO]', `[Response - ${new Date()}]API CMS - Execution time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
      console.log('=================================================')
    }
    return x
  }, (x) => {
    if (process.server) {
      const { status } = x.response || {}
      console.error('Page URL:', route.fullPath)
      console.error('[Response]API CMS - status:', status)
      console.error('[Response]API CMS - endpoint:', x.config.baseURL)
      console.error('[Response]API CMS - headers:', x.config.headers)
      // console.error(`[Response - ${new Date()}]API CMS - Error response time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
      console.error('[Response]API CMS - Error details:', x)
      console.log('=================================================')
    }
    throw x
  })

  // Inject to context as $api
  inject('apiCmsV1', axiosInstance)
}
