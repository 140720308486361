const _API_RESPONSE_TIME_TO_PRINT_LOG = 300
export default function ({ $axios, app, $cookies, route }, inject) {
  const baseURL = process.env.HOST_PROXY
  // const requestTime = new Date().getTime()
  const accessToken = $cookies.get('id') || ''
  const instanceOption = {
    baseURL,
    // meta: {
    //   requestStartedAt: requestTime
    // },
    headers: {
      common: {
        authorization: accessToken
      }
    }
  }
  // Create a custom axios instance
  const axiosInstance = $axios.create(instanceOption)
  axiosInstance.interceptors.request.use((config) => {
    const meta = {
      requestStartedAt: new Date().getTime()
    }
    config.meta = meta
    // config.meta.requestStartedAt = new Date().getTime()
    // config.headers['x-client-uuid'] = clientId
    return config
  }, (x) => {
    if (process.server) {
      console.log('=================================================')
      console.error('Page URL:', route.fullPath)
      console.error('[Request]API MEMBER - Error details:', x)
    }
    // console.log('[Request]API CMS - Headers:', x.config.headers)
    throw x
  })

  axiosInstance.interceptors.response.use((x) => {
    if (process.server) {
      const respTime = new Date().getTime() - (x.config.meta?.requestStartedAt || 0)
      const printRespLog = respTime >= _API_RESPONSE_TIME_TO_PRINT_LOG
      if (printRespLog) {
        console.log('[INFO]', 'Page URL:', route.fullPath)
        console.log('[INFO]', '[Response]API MEMBER - endpoint:', x.config.baseURL)
        console.log('[INFO]', `[Response - ${new Date()}]API MEMBER - Execution time for: ${x.config.url} - ${respTime} ms`)
        console.log('=================================================')
      }
    }
    return x
  }, (x) => {
    if (process.server) {
      console.error('Page URL:', route.fullPath)
      console.error('[Response]API MEMBER - status:', x.response?.status)
      console.error('[Response]API MEMBER - endpoint:', x.config.baseURL)
      console.error(`[Response - ${new Date()}]API MEMBER - Error response time for: ${x.config.url} - ${new Date().getTime() - (x.config.meta?.requestStartedAt || 0)} ms`)
      console.error('[Response]API MEMBER - Error details:', x)
      console.log('=================================================')
    }
    throw x
  })

  inject('apiMember', axiosInstance)
}
