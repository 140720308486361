//
//
//
//
//
//
//
//

export default {
  name: 'Header',
  computed: {
    isHome () {
      return this.$route.path === `/${this.$i18n.locale}/home` || this.$route.params.pathMatch === 'home'
    }
  }
}
