/* eslint-disable */
export default (context, inject) => {
  const { store } = context
  const user = store.state.profile.user
  const fullName = user ? user.firstName + ' ' + user.lastName : ''
  const email = user ? user.email : ''

  window.__lc = window.__lc || {};
  window.__lc.license = 7604291;
  // (function() {
  //   var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
  //   lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
  //   var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
  // })();
  window.__lc.visitor = {
    name: fullName,
    email: email
  };

  let isLoadScripLiveChat = false

  /*
  ** Inject plugin
  */
  const actions = {
    loadScriptLiveChat () {
      if (!isLoadScripLiveChat) {
        (function() {
          var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
          lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
          var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
        })();
        isLoadScripLiveChat = true
      }
    },
    showChat () {
      LC_API.open_chat_window(); // function ของ script live chat
      this.closeChatPanel()
    },
    closeChatPanel(){
      document.querySelector('.select-chatbot-panel').classList.remove('show')
      document.querySelector('.select-chatbot-panel-bg').classList.remove('show')
    }
  }

  inject('livechat', actions)
  context.$livechat = actions
}
