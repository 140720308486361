//
//
//
//
//
//

export default {
  name: 'Card',
  props: {
    cacheKey: {
      type: String,
      default: () => ''
    }
  },
  serverCacheKey: props => props.cacheKey ? 'Card::' + props.cacheKey : false
}
