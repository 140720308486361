//
//
//
//
//
//
//
//
//
//
//

import smoothscroll from 'smoothscroll-polyfill'
export default {
  created () {
    if (!process.server) {
      smoothscroll.polyfill()
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        const mybutton = document.getElementById('scrollToTop')
        const hasScroll = document.body.scrollHeight > document.body.clientHeight
        // console.log(hasScroll, document.body.scrollHeight, document.body.clientHeight)
        if (hasScroll) {
          mybutton.style.display = 'block'
        } else {
          mybutton.style.display = 'none'
        }
      }, 300)
    })

    window.addEventListener('scroll', this.scrollFunction)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollFunction)
  },
  methods: {
    scrollFunction (event) {
      const mybutton = document.getElementById('scrollToTop')
      const hasScroll = document.body.scrollHeight > document.body.clientHeight
      // const height = document.body.scrollHeight - (document.documentElement.offsetHeight)
      if (hasScroll) {
        mybutton.style.display = 'block'
      } else {
        mybutton.style.display = 'none'
      }
    },
    /*
    scollToTop () {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
    */
    scollToTop () {
      /*
      const id = document.getElementById('top')
      this.$smoothScroll({
        scrollTo: id,
        duration: 100,
        offset: -50
      })
      */
      // document.querySelector('#top').scrollIntoView({ behavior: 'smooth' })
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
  }
}
