//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PersonalizedBanner',
  props: {
    isPersonalized: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    desktopClass: {
      type: String,
      default: ''
    },
    mobileClass: {
      type: String,
      default: ''
    },
    bannerUuid: {
      type: String,
      default: ''
    }
  },
  computed: {
    customClassName () {
      if (this.customClass) {
        return ' ' + this.customClass
      }
      return ''
    }
  }
}
