import { render, staticRenderFns } from "./PopularKeyword.vue?vue&type=template&id=2a312832&scoped=true&"
import script from "./PopularKeyword.vue?vue&type=script&lang=js&"
export * from "./PopularKeyword.vue?vue&type=script&lang=js&"
import style0 from "./PopularKeyword.vue?vue&type=style&index=0&id=2a312832&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a312832",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonPopsearch: require('/usr/src/set-nuxt/components/Button/ButtonPopsearch.vue').default})
