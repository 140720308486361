/**
* Specific page route name pattern
*/
const spRouteNamePattern = {
  WelcomePage: /^index_/i,
  home: /^home_/i,
  knowledge: /^knowledge_/i,
  knowledgeArticle: /^knowledge-article_/i,
  knowledgeVideo: /^knowledge-video_/i,
  knowledgeEBook: /^knowledge-ebook_/i,
  knowledgeElearning: /^knowledge-elearning_/i,
  knowledgePodcast: /^knowledge-podcast_/i,
  search: /^search_/i,
  profile: /^profile-following-bookmark_/i,
  knowledgeVideoPlayList: /^knowledge-videoplaylist_/i,
  knowledgeVideoPlayListDetail: /^knowledge-videoplaylist-all_/i,
  knowledgeVideoDetail: /^knowledge-video-all_/i,
  knowledgePodcastPlayList: /^knowledge-podcastplaylist_/i,
  knowledgePodcastPlayListDetail: /^knowledge-podcastplaylist-all_/i,
  knowledgePodcastDetail: /^knowledge-podcast-all_/i,
  glossary: /^glossary_/i,
  glossaryDetail: /^glossary-all_/i,
  error404: /^error-404/i,
  error500: /^error_/i
}
/**
 * Device screen sizes
 */
const deviceSizes = {
  xs: 460,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
}
/**
 * Responsive image sizes
 */
const respImageSizes = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1240,
  xxl: 1920
}
/**
 * Page Type
 */
const pageType = {
  EBook: 'EBookDetail',
  ELearning: 'ELearning'
}

/**
 * Element names
 */
const element = {
  pdpa: 'pdpa'
}

export {
  spRouteNamePattern,
  deviceSizes,
  respImageSizes,
  pageType,
  element
}
