import isFunctionalPage from '~/helpers/functionalPage'
export default async function ({
  app,
  route
}) {
  // Some pages do not require content data
  const _isFunctionalPage = await isFunctionalPage(route.name)
  if (_isFunctionalPage) { return }
  await app.$authUser.authCode()
}
