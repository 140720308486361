//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
import seo from '~/mixins/seo'
import embedScript from '~/mixins/embed-script'
import GoogleTagManager from '~/plugins/statistics/google-tag-manager'
// import livechat from '~/mixins/livechat'
export default {
  // mixins: [seo, embedScript, chatbot, livechat],
  mixins: [seo, embedScript],
  errorCaptured: () => { // error, vm, info
    return false // prevent Nuxt from handling the error
  },
  data () {
    return {
      contentsFollowModal: []
    }
  },
  computed: {
    ...mapGetters({ content: 'contents/getContentData' }),
    title () {
      return `${(this.pageSeoData || {}).title || this.content.title} - ${this.content.websiteInfo ? this.content.websiteInfo.websiteName : ''}`
    },
    gtmTrackingId () {
      const { advanced } = this.content || {}
      const { gtmTrackingId } = advanced || {}
      return gtmTrackingId
    }
  },
  watch: {
    '$route.fullPath': {
      immediate: true,
      handler (newPage, oldPage) {
        const { contentDataLayer: { currentPath } } = this.$store.state
        const isPush = process.client && currentPath !== newPage
        if (isPush) {
          this.$gtmTracking.pagePersonalized()
        }
      }
    }
  },
  async created () {
    // **** get bookmark after login ****
    await this.getBookmark()
    // **** get follow after login ****
    await this.getFollowGroup()
  },
  async mounted () {
    if (!this.$route.path.includes('home')) {
      await this.loadData()
      this.setData()
    }
  },
  methods: {
    async loadData () {
      const getFollowGroupAll = this.$store.dispatch('contents/getFollowGroupAll')
      await getFollowGroupAll
    },
    setData () {
      this.contentsFollowModal = this.$store.state.contents.followGroup_all ? cloneDeep(this.$store.state.contents.followGroup_all) : []
    },
    async updateFollow () {
      // update follow data
      await this.loadData()
      this.setData()
      this.forceRerender()
    }
  },
  head () {
    const i18nSeo = this.$nuxtI18nSeo()
    const gtm = new GoogleTagManager(this.gtmTrackingId)
    return {
      title: this.title,
      htmlAttrs: {
        class: this.isGrayscale,
        ...i18nSeo.htmlAttrs
      },
      meta: [
        ...this.getMetaDataWebsite,
        ...i18nSeo.meta
      ],
      noscript: [...gtm.gtmNoScript()],
      script: [
        ...this.getEmbedScriptDataWebsite,
        ...this.getEmbedScriptDataPage,
        ...gtm.gtmScript(),
        ...this.truehitsScript
      ],
      style: [
        ...this.getStyleCssWebsite
      ],
      link: [
        ...this.setLinkWebsite
        // ...this.livechatPreload
      ],
      __dangerouslyDisableSanitizers: ['innerHTML']
    }
  }
}
