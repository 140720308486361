import Vue from 'vue'

Vue.mixin({
  methods: {
    permissionUser () {
      return this.$authUser.permissionUser()
    },
    logOut () {
      // this.$cookies.remove('access_token')
      // this.$cookies.remove('uref')
      // this.$cookies.set('isMember', false)
      this.$store.dispatch('profile/logOut')
      // window.location.reload()
      this.$authUser.logout()
    }
  }
})
