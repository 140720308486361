//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageLoading',
  data: () => ({
    loading: true,
    fadeOut: false,
    percent: 0
  }),
  methods: {
    start () {
      this.loading = true
      this.fadeOut = false
    },
    finish () {
      setTimeout(() => {
        this.fadeOut = true
        setTimeout(() => {
          this.loading = false
        }, 200)
      }, 800)
    }
  }
}
