/**
 * Google tag manager (GTM)
 * Event tracking
 */
import Vue from 'vue'
import { mapActions } from 'vuex'
import { spRouteNamePattern, pageType } from '~/resources'

Vue.mixin({
  computed: {
    trackingPageName () {
      const routeName = this.$route.name
      const query = this.$route.query || {}
      if (spRouteNamePattern.WelcomePage.test(routeName)) {
        return 'Welcome Page'
      }
      if (spRouteNamePattern.home.test(routeName)) {
        return this.$t('pages.home.title')// 'Home'
      }
      if (this.isKnowledgePage) {
        return this.$t('pages.knowledge.title')// 'Knowledge'
      }
      if (spRouteNamePattern.knowledgeVideoPlayList.test(routeName)) {
        return this.$t('pages.videoplaylist.title')// 'Playlist Video'
      }
      if (spRouteNamePattern.knowledgePodcastPlayList.test(routeName)) {
        return this.$t('pages.podcastplaylist.title')// 'Playlist Podcast'
      }
      if (spRouteNamePattern.glossary.test(routeName)) {
        return this.$t('pages.glossary.title')// 'Glossary'
      }
      if (spRouteNamePattern.glossaryDetail.test(routeName)) {
        const { title } = this.$store.state.contents.content
        return `Glossary Detail ${title}`
      }
      if (spRouteNamePattern.search.test(routeName)) {
        return this.$t('pages.search')// 'Search Result'
      }
      if (spRouteNamePattern.profile.test(routeName)) {
        const type = query.type
        let title = this.$t('pages.bookmark')
        if (type && type.toLowerCase() === 'follow') {
          title = this.$t('pages.follow')
        }
        return title
      }
      return ''
    }
  },
  methods: {
    ...mapActions('contents', ['pageView']),
    /**
     * event click
     */
    clickTracking (opt) {
      // Event tracking
      this.$gtmTracking.onClick({
        element: opt.element,
        title: opt.title,
        contentGroup: opt.contentGroup,
        categoryName: opt.categoryName,
        sectionName: opt.sectionName,
        pageName: this.trackingPageName,
        teamName: opt.teamName
      })
      if (opt.pageType === pageType.EBook || opt.pageType === 'Ebook' || opt.pageType === pageType.ELearning) {
        // Send pageview - pageType = ebook or ELearning
        const pageViewPath = new URL(opt.url)
        this.$gtmTracking.pageView({
          title: opt.title,
          url: pageViewPath.pathname || opt.url
        })
        // add page view
        if (opt.pageUuid) {
          this.addPageView(opt.pageUuid)
        }

        // Big data
        if (opt.gtmDataLayer && !(typeof opt.gtmDataLayer === 'string' && opt.gtmDataLayer === 'null')) {
          this.$gtmTracking.contentPersonalized(opt.gtmDataLayer)
        }
      }
    },
    async addPageView (pageUuid) {
      const payload = { pageUuid }
      await this.pageView(payload)
    }
  }
})
