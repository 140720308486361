//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PictureSource',
  props: {
    alt: {
      type: String,
      default: () => ''
    },
    image: {
      type: Object,
      required: true
    },
    fluid: {
      type: Boolean,
      required: false
    },
    lazyImage: {
      type: Boolean,
      default: () => true
    },
    title: {
      type: String,
      default: () => ''
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      errorImage: require('@/static/images/image-default.jpeg')
    }
  },
  mounted () {
    // console.log('image.url', this.image.url)
    // document.addEventListener('error', function (e) {
    //   if (e.target.nodeName === 'IMG') { e.target.src = this.errorImage }
    // }, true)
  },
  methods: {
    size (size) {
      if (this.$helpers.respImageSizes[size]) {
        let cssProp = 'max-width'
        if (!this.image.type) {
          cssProp = 'min-width' // thumbnail from youtube
        }
        return `(${cssProp}: ${this.$helpers.deviceSizes[size]}px)`
      } else {
        return ''
      }
    },
    imageLoadError () {
      this.$refs.img.src = this.errorImage
      // const ele = document.querySelectorAll('[data-srcset="' + this.image.url + '"]')[0].parentElement
      // // console.log(ele, ele.childElementCount, ele.childNodes)
      // for (let i = 0; i < ele.childElementCount - 1; i++) {
      //   ele.childNodes[i].dataSrcset = this.errorImage
      // }
    }
  }
}
