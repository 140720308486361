//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ContactCenter',
  props: {
    websiteInfo: {
      type: Object,
      required: true
    }
  },
  serverCacheKey: props => 'ContactCenter::' + `${props.websiteInfo.phone}`
}
