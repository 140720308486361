const state = () => ({
  FooterBanner: {}
})
const mutations = {
  setFooterBanner (state, data) {
    state.FooterBanner = data
  }
}
const actions = {
  async clickCounter ({ commit }, data) {
    await this.$axios
      .post(process.env.API_ENDPOINT + `banner/click/${data}`)
      .then((res) => {
        return res.data
      })
      .catch(err => err)
  },
  async getFooterBanner ({ commit }, payload) {
    const url = process.env.API_ENDPOINT + `banner/${process.env.WEB_CHANNEL}/${this.$i18n.locale}/${payload.bannerUuid}`
    await this.$axios
      .get(url)
      .then(res => commit('setFooterBanner', res.data))
      .catch(err => err)
  }
}
const getters = {
  getFooterBannerData: (state) => {
    return state.FooterBanner
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
