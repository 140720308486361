//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'InputSearch',
  data () {
    return {
      inputText: ''
    }
  },
  mounted () {
    if (this.$route.query.keyword) {
      this.inputText = this.$route.query.keyword
    }
  },
  methods: {
    clickSearch () {
      this.$gtmTracking.pushDataLayerSiteSearch(this.inputText, 'search_box_panel') // push datalayer
      this.$bvModal.hide('modal-search')
      this.$router.push(this.localeRoute({ name: 'search', path: '/search', query: { keyword: this.inputText }, params: { inputSearch: this.inputText } }))
      if (this.$route.path.includes('search')) {
        const link = window.location.origin + this.$route.fullPath
        const oldInput = link.split('keyword=')[1]
        const newlink = link.replace(oldInput, this.inputText)
        window.location.href = newlink
      }
    }
  },
  update () {
    this.$forceUpdate()
  }
}
