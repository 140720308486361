import Vue from 'vue'

Vue.mixin({
  methods: {
    personalizedBannerTrigger (bannerUuid, reRenderBanner) {
      // set observer for trigger when style change
      // const observer = new MutationObserver(() => {
      //   reRenderBanner()

      //   console.log('style is change')
      // })
      // observer.observe(document.querySelector(`.banner-${bannerUuid}`), {
      //   attributes: true,
      //   attributeFilter: ['style', 'class']
      // })
      const element = document.querySelector(`.banner-${bannerUuid}`)
      const observer = new IntersectionObserver(() => {
        // const compStyles = window.getComputedStyle(element)
        const banner = document.querySelector(`.banner-${bannerUuid} .flickity-viewport`)
        if (banner && parseInt(banner.style.height) <= 0) {
          reRenderBanner()
        }
      }, { rootMargin: '250px 250px 250px 250px' })
      if (element) {
        observer.observe(element)
      }
    }
  }
})
