export default (context, inject) => {
  const request = async function (config, error) {
    try {
      const resp = await context.$axios(config)

      return resp.data
    } catch (err) {
      // handle error
      const { $helpers, redirect } = context
      $helpers.getContentErrorHandler(err, redirect)
    }
  }

  request.$get = function (path, config) {
    return request(mergeConfig({ method: 'get', url: path, progress: false }, config))
  }
  request.$post = function (path, data, config) {
    return request(mergeConfig({ method: 'post', url: path, data, progress: false }, config))
  }

  function mergeConfig (base, extra) {
    if (typeof extra === 'object' && extra !== null) {
      Object.assign(base, extra)
    }
    return base
  }

  inject('appApi', request)
  context.$appApi = request
}
