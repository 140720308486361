//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
export default {
  data () {
    return {
      marqueeText: '',
      setData: [],
      setDataMai: [],
      setMarket: {
        setDropdownText: '',
        datetime: '',
        market_status: '',
        setLast: '',
        setChange: '',
        setPerChange: '',
        setVolume: '',
        setValue: ''
      },
      rollDistance: 0,
      marqueeIndex: 0,
      transitionSpeed: 0,
      intervalTimer: null,
      timeoutTimer: null
    }
  },
  computed: {
    marqueeListLength () {
      return this.setData.index.length || 0
    }
  },
  async mounted () {
    this.$nextTick(() => {
      if (this.$refs.dropdownMarket && this.$refs.dropdownMarket.$el) {
        const dropdownMarket = this.$refs.dropdownMarket.$el
        const buttonDpd = dropdownMarket.children[0]
        if (buttonDpd) {
          buttonDpd.ariaLabel = 'market dropdown'
        }
        // const buttonDropdown = dropdownMarket.queryselector('.btn.dropdown-toggle')
      }
    })
    await this.getMarketSummary()
    if (this.setData.market_status) {
      this.startUpdateStatus()
    }
  },
  beforeDestroy () {
    this.intervalTimer && clearInterval(this.intervalTimer)
  },
  destroyed () {
    this.intervalTimer && clearInterval(this.intervalTimer)
  },
  methods: {
    async getMarketSummary () {
      // delete this.$axios.defaults.headers.common['x-api-key']
      await axios.get('https://api.settrade.com/api/market/SET/info').then((res) => { this.setData = res.data })
      // delete this.$axios.defaults.headers.common['x-api-key']
      await axios.get('https://api.settrade.com/api/market/mai/info').then((res) => { this.setDataMai = res.data })
      this.setData.index = [...this.setData.index, ...this.setDataMai.index]
      this.setMarket = {
        setDropdownText: this.setData.index[this.marqueeIndex] ? this.setData.index[this.marqueeIndex].index_display_name : 0,
        datetime: this.setData.datetime ? this.setData.datetime : this.$moment().format('DD/MM/yyyy hh:mm:ss'),
        market_status: this.setData.market_status ? this.setData.market_status : 'Close',
        setLast: this.setData.index[this.marqueeIndex].last,
        setChange: this.setData.index[this.marqueeIndex].change,
        setPerChange: this.setData.index[this.marqueeIndex].percent_change,
        setVolume: this.setData.index[this.marqueeIndex].total_volume,
        setValue: this.setData.index[this.marqueeIndex].total_value
      }
    },
    startUpdateStatus () {
      this.intervalTimer = setInterval(async () => {
        if (this.marqueeIndex === this.marqueeListLength - 1) {
          this.marqueeIndex = 0
          await this.getMarketSummary()
          await this.changeStatus()
        } else {
          ++this.marqueeIndex
          await this.getMarketSummary()
          await this.changeStatus()
        }
      }, 4000)
    },
    changeStatus () {
      const eleLast = document.querySelectorAll('.marquee')
      // add class active
      eleLast.forEach((ele) => {
        ele.classList.add('active')
      })
      // remove class active
      setTimeout(() => {
        eleLast.forEach((ele) => {
          ele.classList.remove('active')
        })
      }, 250)
    },
    async onChange (index) {
      this.marqueeIndex = index
      await this.getMarketSummary()
      await this.changeStatus()
    },
    checkStyleNumber (number) {
      if (Number(number) === 0) {
        return 'set-tantamount'
      } else if (Number(number) < 0) {
        return 'set-minus'
      } else {
        return 'set-plus'
      }
    },
    mouseoverHandle () {
      this.intervalTimer && clearInterval(this.intervalTimer)
    },
    mouseleaveHandel () {
      if (this.setData.market_status) {
        this.startUpdateStatus()
      }
    }
  }
}
