const specificPagePath = [
  'preview-form',
  'profile/detail',
  'profile/following-bookmark',
  'search',
  'glossary',
  'error/oldversion',
  'error/404',
  'error'
]
const matchSpecificPage = (route, locale) => {
  const { slug } = route.params
  const path = route.path.replace('/' + slug, '')
  const url = path.search('th') === -1 ? `/${locale}/${path}` : path
  const pattern = new RegExp('^' + url + '$')
  const found = specificPagePath.filter(function (value) {
    const newPath = `/${locale}/${value}`
    return pattern.test(newPath)
  })
  return found.length > 0
}
const state = () => ({
  content: null,
  specificPagePath,
  knwlFollowing: [],
  contents_follow_group: [],
  contents_relate_article: null,
  contents_recommendation: null,
  contents_latest: {},
  contents_popular: null,
  contents_member_follower: [],
  contents_member_bookmark: [],
  investnow_solution: [],
  set_activity_ads: [],
  set_activity_calendar: [],
  knowledge_in_cate: [],
  knowledge_in_followgroup: [],
  elearning_in_cate: [],
  knowledge_landing: [],
  knowledge_in_glossary: [],
  followGroup_all: [],
  contents_isBookmark: false,
  video_playlist_videos: [],
  hasDataLayer: false
})

const mutations = {
  /*
   ** set data content
   */
  setContent (state, data) {
    state.content = data
  },
  setContentsMember (state, data) {
    state.knwlFollowing = data
  },
  setFollowGroupAll (state, data) {
    state.followGroup_all = data
  },
  setContentsFollowGroup (state, data) {
    state.contents_follow_group = data
  },
  setContentsRelateArticle (state, data) {
    state.contents_relate_article = data
  },
  setContentsRecommendation (state, data) {
    state.contents_recommendation = data
  },
  setContentsLatest (state, data) {
    state.contents_latest = data
  },
  setContentsPopular (state, data) {
    state.contents_popular = data
  },
  setInvestnowSolution (state, data) {
    state.investnow_solution = data
  },
  setContentMemberBookmark (state, data) {
    state.contents_member_bookmark = data
  },
  setContentMemberFollower (state, data) {
    state.contents_member_follower = data
  },
  setActivityAds (state, data) {
    state.set_activity_ads = data
  },
  setActivityCalendar (state, data) {
    state.set_activity_calendar = data
  },
  setKnowledgeInCate (state, data) {
    state.knowledge_in_cate = data
  },
  setKnowledgeInFollowGroup (state, data) {
    state.knowledge_in_followGroup = data
  },
  setKnowledgeLanding (state, data) {
    state.knowledge_landing = data
  },
  setElearningInCate (state, data) {
    state.elearning_in_cate = data
  },
  setContentsIsBookMark (state, data) {
    state.contents_isBookmark = data
  },
  setVideoPlaylistVideos (state, data) {
    state.video_playlist_videos = data
  },
  setKnowledgeInGlossary (state, data) {
    state.knowledge_in_glossary = data
  },
  setHasDataLayer (state, data) {
    state.hasDataLayer = data
  }
}

const actions = {
  /*
   ** get data content
   */
  async getContent ({ commit, state, rootState }, payload) {
    payload = payload || {}

    // detect device from query
    const isDesktop = (payload.query.device || 'desktop').toLowerCase() === 'desktop'
    const userDevice = this.$device.isDesktopOrTablet && isDesktop ? 'Desktop' : 'Mobile'
    const preview = payload.query.mode === 'preview'
    let url = preview ? payload.path : payload.fullPath
    const isSpecificPage = await matchSpecificPage(payload, this.$i18n.locale)
    if (isSpecificPage) {
      url = `/${this.$i18n.locale}/home`
    }

    await this.$appApi
      .$get(process.env.API_ENDPOINT + 'content/byUrl', {
        params: {
          channel: process.env.WEB_CHANNEL,
          url,
          device: userDevice,
          lang: this.$i18n.locale
        }
      })
      .then(res => commit('setContent', res))
      .catch(err => err)
  },
  /*
   ** get data content member following
   */
  async getContentMember ({ commit, rootState }, payload) {
    payload = payload || {}
    await this.$axios
      .get(process.env.API_ENDPOINT + 'knowledge/following', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageIndex: payload.pageIndex || 0,
          pageSize: payload.pageSize || 5,
          setMemberId: this.$authUser.userRef() // rootState.profile.user ? rootState.profile.user.userRef : ''
        }
      })
      .then(res => commit('setContentsMember', res ? res.data : []))
      .catch(err => err)
  },
  /*
   ** get data content member following
   */
  async getFollowGroupAll ({ commit, rootState }, payload) {
    payload = payload || {}
    await this.$axios
      .get(process.env.API_ENDPOINT + 'followGroup/all', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          setMemberId: this.$authUser.userRef() // rootState.profile.user ? rootState.profile.user.userRef : ''
        }
      })
      .then(res => commit('setFollowGroupAll', res ? res.data : []))
      .catch(err => err)
  },
  /*
   ** get data content follow group
   */
  async getContentFollowGroup ({ commit, rootState }, payload) {
    // **** limit count service
    commit('utility/setLCS_FollowGroup', rootState.utility.limit_call_service.element_follow_group + 1, { root: true })

    payload = payload || {}
    // Purpose: preview mode
    let url = process.env.API_ENDPOINT + 'followGroup/pageRelated'
    if (payload.mode === 'preview') {
      url = process.env.API_ENDPOINT + 'followGroup/pageRelatedPreview'
    }
    await this.$axios
      .get(url, {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageUuid: payload.pageUuid,
          setMemberId: this.$authUser.userRef() // rootState.profile.user ? rootState.profile.user.userRef : ''
        }
      })
      .then(res => commit('setContentsFollowGroup', res ? res.data : []))
      .catch(err => err)
  },
  /*
   ** get data latest update content
   */
  async getContentRelateArticle ({ commit, rootState }, payload) {
    // **** limit count service
    commit('utility/setLCS_RelateArticle', rootState.utility.limit_call_service.element_relate_article + 1, { root: true })

    payload = payload || {}

    // Purpose: preview mode
    let url = process.env.API_ENDPOINT + 'knowledge/relatedByFollowGroup'
    if (payload.mode === 'preview') {
      url = process.env.API_ENDPOINT + 'knowledge/relatedByFollowGroupPreview'
    }
    await this.$axios
      .get(url, {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageUuid: payload.pageUuid, // 'b766664d-292d-436b-80a6-4dfc3c4deb71',
          pageIndex: payload.pageIndex || 0,
          pageSize: payload.pageSize || 5,
          setMemberId: this.$authUser.userRef(), // rootState.profile.user ? rootState.profile.user.userRef : '',
          pageType: payload.pageType
        }
      })
      .then(res => commit('setContentsRelateArticle', res ? res.data : []))
      .catch(err => err)
  },
  /*
   ** get data latest update content
   */
  async getContentRecommendation ({ commit, rootState }, payload) {
    // **** limit count service
    commit('utility/setLCS_Recommendation', rootState.utility.limit_call_service.element_recommendation + 1, { root: true })

    payload = payload || {}

    // Purpose: preview mode
    let url = process.env.API_ENDPOINT + 'knowledge/withFollowGroup'
    if (payload.mode === 'preview') {
      url = process.env.API_ENDPOINT + 'knowledge/withFollowGroupPreview'
    }
    await this.$axios
      .get(url, {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageUuid: payload.pageUuid,
          pageIndex: payload.pageIndex || 0,
          pageSize: payload.pageSize || 5,
          setMemberId: this.$authUser.userRef() // rootState.profile.user ? rootState.profile.user.userRef : ''
        }
      })
      .then(res => commit('setContentsRecommendation', res ? res.data : []))
      .catch(err => err)
  },
  /*
   ** get data latest update content
   */
  async getContentLatest ({ commit }, payload) {
    payload = payload || {}
    await this.$axios
      .get(process.env.API_ENDPOINT + 'knowledge/latest', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageIndex: payload.pageIndex || 0,
          pageSize: payload.pageSize || 5
        }
      })
      .then(res => commit('setContentsLatest', res ? res.data : {}))
      .catch(err => err)
  },
  /*
   ** get data latest skip take
   */
  async getKnowledgeLatestSkipTake ({ commit }, payload) {
    payload = payload || {}
    await this.$axios
      .get(process.env.API_ENDPOINT + 'knowledge/latest-skip-take', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          skip: payload.pageIndex || 0,
          take: 5
          // pageIndex: payload.pageIndex || 0,
          // pageSize: payload.pageSize || 5
        }
      })
      .then(res => commit('setContentsLatest', res ? res.data : {}))
      .catch(err => err)
  },
  /*
   ** get data popular content
   */
  async getContentPopular ({ commit }, payload) {
    payload = payload || {}
    await this.$axios
      .get(process.env.API_ENDPOINT + 'knowledge/popular', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageIndex: payload.pageIndex || 0,
          pageSize: payload.pageSize || 7
        }
      })
      .then(res => commit('setContentsPopular', res ? res.data : null))
      .catch(err => err)
  },
  /*
   ** get data investnow solution
   */
  async getInvestnowSolution ({ commit }) {
    await this.$axios
      .get(process.env.API_ENDPOINT + 'investnow/solution', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale
        }
      })
      .then(res => commit('setInvestnowSolution', res ? res.data : []))
      .catch(err => err)
  },
  /*
   ** get data user history bookmark
   */
  async getMemberBookmarkAndFollow ({ commit, rootState }, payload) {
    const userDevice = this.$device.isDesktop ? 'Desktop' : 'Mobile'
    await this.$axios
      .get(
        process.env.API_ENDPOINT + 'SetMember/getmemberbookmarkandfollowgroup',
        {
          params: {
            UserRef: this.$authUser.userRef(), // rootState.profile.user ? rootState.profile.user.userRef : '',
            channel: process.env.WEB_CHANNEL,
            device: userDevice,
            lang: this.$i18n.locale,
            pageIndex: payload.pagination.currentPage - 1, // Unuse On Follow Group
            pageSize: payload.pagination.perPage, // Unuse On Follow Group
            pageTypes: payload.pageTypes,
            skip: payload.pagination.skip, // Useing On Follow Group
            take: payload.pagination.take // Useing On Follow Group
          }
        }
      )
      .then((res) => {
        if (payload.pageTypes === 'FollowGroup') {
          commit('setContentMemberFollower', res ? res.data : [])
        } else {
          commit('setContentMemberBookmark', res ? res.data : [])
        }
      })
      .catch(err => err)
  },
  /*
   ** get data user history follower
   */
  async getMemberFollower ({ commit, rootState }) {
    const userDevice = this.$device.isDesktop ? 'Desktop' : 'Mobile'
    await this.$axios
      .get(process.env.API_ENDPOINT + 'SetMember/getmemberfollower', {
        params: {
          UserRef: this.$authUser.userRef(), // rootState.profile.user.userRef,
          channel: process.env.WEB_CHANNEL,
          device: userDevice,
          lang: this.$i18n.locale
        }
      })
      .then(res => commit('setContentMemberFollower', res ? res.data : []))
      .catch(err => err)
  },
  async getSETActivityAds ({ commit, rootState }, payload) {
    // **** limit count service
    commit('utility/setLCS_ActivityAds', rootState.utility.limit_call_service.element_activity_ads + 1, { root: true })
    const dateNow = new Date()
    this.dateNow = dateNow.setFullYear(this.$moment().year())
    // const sd = new Date(dateNow).setMonth(dateNow.getMonth() - 6)
    const ed = new Date(dateNow).setMonth(dateNow.getMonth() + 6)
    await this.$axios
      .get(process.env.API_ENDPOINT + 'SetEvent/GetActivityCalendar', {
        params: {
          targetAudience: 'R',
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          startDate: new Date(dateNow).toISOString().slice(0, 10),
          endDate: new Date(ed).toISOString().slice(0, 10),
          tabType: payload.tabType
        }
      })
      .then(res => commit('setActivityAds', res.data))
      .catch(err => err)
  },
  async getSETActivityCalendarList ({ commit, rootState }, payload) {
    // **** limit count service
    commit('utility/setLCS_ActivityCalendar', rootState.utility.limit_call_service.element_activity_calendar + 1, { root: true })

    const dateNow = new Date()
    this.dateNow = dateNow.setFullYear(this.$moment().year())
    // const sd = new Date(dateNow).setMonth(dateNow.getMonth() - 6)
    const ed = new Date(dateNow).setMonth(dateNow.getMonth() + 6)
    await this.$axios
      .get(process.env.API_ENDPOINT + 'SetEvent/GetActivityCalendarList', {
        params: {
          targetAudience: 'P',
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          startDate: new Date(dateNow).toISOString().slice(0, 10),
          endDate: new Date(ed).toISOString().slice(0, 10),
          month: payload.month,
          year: payload.year,
          tabType: payload.tabType,
          pageIndex: payload.pageIndex - 1,
          pageSize: payload.pageSize
        }
      })
      .then(res => commit('setActivityCalendar', res.data))
      .catch(commit('setActivityCalendar', false))
  },
  /*
   ** get KnowledgeInCate
   */
  async getKnowledgeInCate ({ commit }, payload) {
    // Purpose: preview mode
    let url = process.env.API_ENDPOINT + 'knowledge/knowledgeInCate'
    if (payload.mode === 'preview') {
      url = process.env.API_ENDPOINT + 'knowledge/knowledgeInCatePreview'
    }
    await this.$axios
      .get(url, {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          catePageUuid: payload.catePageUuid,
          pageIndex: payload.pageIndex,
          pageSize: payload.pageSize,
          pageType: payload.pageTypes
        }
      })
      .then(res => commit('setKnowledgeInCate', res.data))
      .catch(err => err)
  },
  /*
   ** get Knowledge in follow group
   */
  async getKnowledgeInFollowGroup ({ commit }, payload) {
    // Purpose: preview mode
    let url = process.env.API_ENDPOINT + 'knowledge/knowledgeInFollowGroup'
    if (payload.mode === 'preview') {
      url = process.env.API_ENDPOINT + 'knowledge/knowledgeInFollowGroupPreview'
    }
    await this.$axios
      .get(url, {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          catePageUuid: payload.catePageUuid,
          pageIndex: payload.pageIndex,
          pageSize: payload.pageSize,
          pageType: payload.pageTypes
        }
      })
      .then(res => commit('setKnowledgeInFollowGroup', res.data))
      .catch(err => err)
  },
  /*
   ** get KnowledgeInCatge
   */
  async getKnowledgeLanding ({ commit }, payload) {
    await this.$axios
      .get(process.env.API_ENDPOINT + 'knowledge/knowledgeLanding', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          cateSlug: payload.cateSlug,
          pageIndex: payload.pageIndex,
          pageSize: payload.pageSize,
          pageType: payload.pageTypes
        }
      })
      .then(res => commit('setKnowledgeLanding', res.data))
      .catch(err => err)
  },
  /*
   ** page share
   */
  async pageShared ({ commit }, payload) {
    await this.$axios
      .post(process.env.API_ENDPOINT + 'content/addPageShare', {
        pageUuid: payload.pageUuid
      })
      .then((res) => {
        return res.data
      })
      .catch(err => err)
  },
  /*
   ** page views
   */
  async pageView ({ commit }, payload) {
    await this.$axios
      .post(process.env.API_ENDPOINT + 'content/addPageView', {
        pageUuid: payload.pageUuid
      })
      .then((res) => {
        return res.data
      })
      .catch(err => err)
  },
  async getContentBookmark ({ commit, rootState }, payload) {
    // **** limit count service
    commit('utility/setLCS_BookMark', rootState.utility.limit_call_service.element_bookmark + 1, { root: true })
    let status = false
    await this.$axios
      .get(
        process.env.API_ENDPOINT +
          'Setmember/checksetmemberbookmarkstatusbypage',
        {
          params: {
            userRef: payload.userRef,
            pageUuid: payload.pageUuid
          }
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          status = true
          commit('setContentsIsBookMark', true)
        }
      })
      .catch(err => err)
    return status
  },
  async getContentFollow ({ commit }, payload) {
    let response = null
    await this.$axios
      .get(
        process.env.API_ENDPOINT +
          'Setmember/checksetmemberfollowstatusbypage',
        {
          params: {
            userRef: payload.userRef,
            contentGroupUuid: payload.contentGroupUuid
          }
        }
      )
      .then((res) => {
        response = res.data.status
      })
      .catch(err => err)
    return response
  },
  /*
    ** get ElearningInCate
  */
  async getElearningInCate ({ commit }, payload) {
    // Purpose: preview mode
    let url = process.env.API_ENDPOINT + 'elearning/elearningInCate'
    if (payload.mode === 'preview') {
      url = process.env.API_ENDPOINT + 'elearning/elearningInCatePreview'
    }
    await this.$axios
      .get(url, {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          catePageUuid: payload.catePageUuid,
          pageIndex: payload.pageIndex,
          pageSize: payload.pageSize
        }
      })
      .then(res => commit('setElearningInCate', res.data))
      .catch(err => err)
  },
  /*
    ** get VideoPlaylistVideos
  */
  async getVideoPlaylistVideos ({ commit }, payload) {
    const url = process.env.API_ENDPOINT + 'video-playlist/videos'
    await this.$axios
      .get(url, {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageUuid: payload.pageUuid,
          pageIndex: payload.pageIndex,
          pageSize: payload.pageSize
        }
      })
      .then(res => commit('setVideoPlaylistVideos', res.data))
      .catch(err => err)
  },
  /*
   ** get KnowledgeInGlossaary
   */
  async getKnowledgeInGlossary ({ commit }, payload) {
    // Purpose: preview mode
    let url = process.env.API_ENDPOINT + 'knowledge/knowledgeInGlossary'
    if (payload.mode === 'preview') {
      url = process.env.API_ENDPOINT + 'knowledge/knowledgeInGlossaryPreview'
    }
    await this.$axios
      .get(url, {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageUuid: payload.pageUuid,
          pageIndex: payload.pageIndex,
          pageSize: payload.pageSize
        }
      })
      .then(res => commit('setKnowledgeInGlossary', res.data))
      .catch(err => err)
  },
  async addPageRating ({ commit }, data) {
    const result = await this.$axios
      .post(process.env.API_ENDPOINT + 'content/addPageRating', data)
      .then((res) => {
        return res
      })
      .catch(err => err)
    return result
  }
}

const getters = {
  getContentData: (state) => {
    return state.content ? state.content : {}
  },
  getContentLatestData: (state) => {
    return state.contents_latest
  },
  getInvestnowSolutionData: (state) => {
    return state.investnow_solution
  },
  getKnowledgeInCate: (state) => {
    return state.knowledge_in_cate
  },
  getKnowledgeLandingData: (state) => {
    return state.knowledge_landing
  },
  getVideoPlaylistVideosData: (state) => {
    return state.video_playlist_videos
  },
  getKnowledgeInGlossaryData: (state) => {
    return state.knowledge_in_glossary
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
