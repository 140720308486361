const state = () => ({
  heroBanner: {},
  adsBanner: {},
  investnow_solution: [],
  contents_popular: [],
  contents_latest: {},
  followGroup_all: [],
  knwlFollowing: {}
})

const mutations = {
  setHeroBanner (state, data) {
    state.heroBanner = data
  },
  setAdsBanner (state, data) {
    state.adsBanner = data
  },
  setFollowGroupAll (state, data) {
    state.followGroup_all = data
  },
  setKnwlFollowing (state, data) {
    state.knwlFollowing = data
  },
  setInvestnowSolution (state, data) {
    state.investnow_solution = data
  },
  setContentLatest (state, data) {
    state.contents_latest = data
  },
  setContentsPopular (state, data) {
    state.contents_popular = data
  }
}

const actions = {
  async getHomeResource ({ commit }) {
    await this.$appApi
      .$get(process.env.API_ENDPOINT + 'investnow/home-resource', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale
        }
      })
      .then((res) => {
        commit('setHeroBanner', res.heroBanner)
        commit('setAdsBanner', res.adsBanner)
        commit('setKnwlFollowing', res.knwlFollowing)
        commit('setFollowGroupAll', res.followGroup)
        commit('setInvestnowSolution', res.solution)
        commit('setContentLatest', res.knwlLatest)
        commit('setContentsPopular', res.knwlPopular)
      })
      .catch(err => err)
  },
  /*
   ** get data latest update content
   */
  async getContentLatest ({ commit }, payload) {
    payload = payload || {}
    await this.$axios
      .get(process.env.API_ENDPOINT + 'knowledge/latest', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageIndex: payload.pageIndex || 0,
          pageSize: payload.pageSize || 5
        }
      })
      .then(res => commit('setContentLatest', res ? res.data : {}))
      .catch(err => err)
  }
}
const getters = {
  getHeroBanner (state) {
    return state.heroBanner || {}
  },
  getAdsBanner (state) {
    return state.adsBanner || {}
  },
  getFollowGroupAll (state) {
    return state.followGroup_all || []
  },
  getKnwlFollowing (state) {
    return state.knwlFollowing || {}
  },
  getInvestnowSolution (state) {
    return state.investnow_solution || []
  },
  getContentLatest (state) {
    return state.contents_latest || {}
  },
  getContentsPopular (state) {
    return state.contents_popular || {}
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
