import { render, staticRenderFns } from "./Picture.vue?vue&type=template&id=8a6c097c&scoped=true&"
import script from "./Picture.vue?vue&type=script&lang=js&"
export * from "./Picture.vue?vue&type=script&lang=js&"
import style0 from "./Picture.vue?vue&type=style&index=0&id=8a6c097c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a6c097c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PictureSource: require('/usr/src/set-nuxt/components/Image/PictureSource.vue').default,Picture: require('/usr/src/set-nuxt/components/Image/Picture.vue').default})
