//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Sitemap',
  props: {
    menuName: {
      type: String,
      default: () => ''
    },
    menuList: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleClickTracking (data) {
      if (!data.evtTeamName || !data.url) { return false }
      const dataTeamName = '' // data.evtTeamName // Data Category(Team)
      const dataAction = this.menuName ? `Menu ${this.menuName}` : '' // Data Action(Section)
      const label = data.name
      this.clickTracking({
        title: label,
        sectionName: dataAction,
        teamName: dataTeamName
      })
    }
  }
}
