//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NavberSubMenuMobile',
  props: {
    menuName: {
      type: String,
      required: true
    },
    menuList: {
      type: Array,
      required: true
    }
  },
  serverCacheKey: props => 'NavberSubMenuMobile::' + props.menuName + '_' + props.menuList.map((r) => { return r.name + '_' + r.url + '_' + r.openNewTab }).join('_'),
  methods: {
    handleClickTracking (data) {
      const eventTracking = data.event_tracking
      const isEtk = eventTracking.is_etk
      const dataTeamName = '' // eventTracking.data_team_name // Data Category(Team)
      const dataAction = this.menuName ? `Menu ${this.menuName}` : '' // Data Action(Section)
      const label = data.name
      if (isEtk) {
        this.clickTracking({
          title: label,
          sectionName: dataAction,
          teamName: dataTeamName
        })
      }
    }
  }
}
