//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'Search',
  computed: {
    ...mapState({
      KeywordPopular: state => state.search.topSearch,
      contentsPopular: state => state.contents.contents_popular
    })
  },
  created () {
    if (!this.$store.state.search.topSearch) {
      this.$store.dispatch('search/getTopSearch')
    }
    if (!this.$store.state.contents.contents_popular) {
      this.$store.dispatch('contents/getContentPopular')
    }
  }
}
