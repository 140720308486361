//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NavbarBrand',
  computed: {
    content () {
      return this.$store.state.contents.content || {}
    },
    websiteInfo () {
      return this.content.websiteInfo || {}
    }
  }
}
