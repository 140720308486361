import Vue from 'vue'

Vue.mixin({
  data () {
    return {
      elements: []
    }
  },
  mounted () {
    const selector = [
      '[animate-in="true"]:not(.animate-in)'
    ]
    this.elements = document.querySelectorAll(selector.join(',')) || []
    window.addEventListener('scroll', this.handleElementInView)
    this.handleElementInView()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleElementInView)
  },
  methods: {
    elementInView (el) {
      let top = el.offsetTop
      let height = 50 // el.offsetHeight / 6
      if (el.offsetHeight >= 200) {
        height = 100
      }

      while (el.offsetParent) {
        el = el.offsetParent
        top += el.offsetTop
      }

      return (top >= window.pageYOffset && (top + height) <= (window.pageYOffset + window.innerHeight))
    },
    handleElementInView () {
      this.elements.forEach((el) => {
        const visible = this.elementInView(el)
        if (visible) {
          el.classList.add('animate-in')
        }
      })
    }
  }
})
