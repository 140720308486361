//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'WebSiteSocial',
  props: {
    websiteInfo: {
      type: Object,
      required: true
    }
  },
  serverCacheKey: props => 'WebSiteSocial::' + `${props.websiteInfo.facebookUrl}_${props.websiteInfo.twitterUrl}_${props.websiteInfo.youtubeUrl}_${props.websiteInfo.lineUrl}_${props.websiteInfo.instagramUrl}_${props.websiteInfo.contactUrl}`
}
