//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PopularKeyContent',
  props: {
    content: {
      type: Array,
      default: () => {}
    }
  },
  // serverCacheKey: props => 'PopularKeyContent::' + JSON.stringify(props.content),
  methods: {
    handleClickTracking (item) {
      this.clickTracking({
        title: item.name,
        pageUuid: item.pageUuid,
        pageType: item.pageType,
        sectionName: this.$t('search-recommend'),
        url: item.url,
        gtmDataLayer: item.gtmDataLayer,
        teamName: '' // process.env.GTM_EVENT_CATEGORY_TEAM
      })
    }
  }
}
