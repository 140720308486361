const state = () => ({
  topSearch: null,
  searchResult: []
})

const mutations = {
  setTopSearch (state, data) {
    state.topSearch = data
  },
  setSearchResult (state, data) {
    state.searchResult = data
  }
}

const actions = {
  /*
  ** get data top 10 search
  */
  async getTopSearch ({ commit, state }) {
    await this.$axios
      .get(process.env.API_ENDPOINT + 'search/top10search', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale
        }
      })
      .then(res => commit('setTopSearch', res ? res.data : null))
      .catch(err => err)
  },
  async getAllSearch ({ commit }, payload) {
    await this.$axios
      .get(process.env.API_ENDPOINT + 'search/all', {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: 'TH',
          keyword: payload.keyword,
          filter: payload.filter,
          echType: payload.echType,
          orderBy: payload.orderBy
        }
      })
      .then(res => commit('setSearchResult', res ? res.data : []))
      .catch(err => err)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
