import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=39fe8d0d&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmartBanner: require('/usr/src/set-nuxt/components/SmartBanner/SmartBanner.vue').default,MarketSummary: require('/usr/src/set-nuxt/components/MarketSummary/MarketSummary.vue').default,Navbar: require('/usr/src/set-nuxt/components/Navbar/Navbar.vue').default,Header: require('/usr/src/set-nuxt/components/Header/Header.vue').default})
