//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModalUnBookmark',
  methods: {
    submitBookmark () {
      this.$bvModal.hide('modal-unbookmark')
      this.$store.commit('handle/setActive', false)
      const [pageUuid, index, type] = [
        this.$store.state.handle.handle.bookmark,
        this.$store.state.handle.handle.index,
        this.$store.state.handle.handle.type
      ]
      this.handleBookMark(pageUuid, index, type)
      this.$store.dispatch('handle/setIsBookmarked', false)
    }
  }
}
