//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ButtonPopsearch',
  props: {
    follow: {
      type: Boolean
    },
    label: {
      type: String,
      default: () => ''
    }
  },
  serverCacheKey: props => 'ButtonPopsearch::' + `${props.label}_${props.follow}`
}
