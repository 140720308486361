//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Logo',
  props: {
    websiteTitle: {
      type: String,
      default: () => ''
    }
  },
  serverCacheKey: props => 'WebsiteLogo::' + props.websiteTitle,
  methods: {
    iconClicked () {
      if (this.$route.name.includes('home')) {
        this.scollToTop()
      }
    },
    scollToTop () {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
  }
}
