/**
 * Columns
 */
const columns = {
  container1: ['col-md-12'],
  container1_1: ['col-md-6', 'col-md-6'],
  container1_2: ['col-md-4', 'col-md-8'],
  container2_1: ['col-md-8', 'col-md-4'],
  container10_2: ['col-md-10', 'col-md-2'],
  container2_10: ['col-md-2', 'col-md-10'],
  container1_1_1: ['col-md-4', 'col-md-4', 'col-md-4'],
  container1_8_3: ['col-lg-1', 'col-md-8', 'col-md-4 col-lg-3'], // core layout
  container6_3_3: ['col-md-6', 'col-md-3', 'col-md-3'],
  container3_3_6: ['col-md-3', 'col-md-3', 'col-md-6'],
  container3_6_3: ['col-md-3', 'col-md-6', 'col-md-3'],
  container1_1_1_1: ['col-md-3', 'col-md-3', 'col-md-3', 'col-md-3']
}
const columnsMobile = {
  container1: ['col-12'],
  container1_1: ['col-6', 'col-6'],
  container1_2: ['col-4', 'col-8'],
  container2_1: ['col-8', 'col-4'],
  container10_2: ['col-10', 'col-2'],
  container2_10: ['col-2', 'col-10'],
  container1_1_1: ['col-4', 'col-4', 'col-4'],
  container1_8_3: ['col-1', 'col-8', 'col-4 col-3'], // core layout
  container6_3_3: ['col-6', 'col-3', 'col-3'],
  container3_3_6: ['col-3', 'col-3', 'col-6'],
  container3_6_3: ['col-3', 'col-6', 'col-3'],
  container1_1_1_1: ['col-3', 'col-3', 'col-3', 'col-3']
}

export default (context, inject) => {
  const dynamicContent = {
    /**
     * Find element
     * @param {string} elementName
     */
    isElement: (elementName) => {
      const regx = /^element_/i
      return regx.test(elementName)
    },
    /**
     * Find container
     * @param {string} elementName
     */
    isContainer: (elementName) => {
      const regx = /^container/i
      return regx.test(elementName)
    },

    /**
     * Get column class
     * @param {string} elementName
     * @param {string} contentDevice
     */
    getColumnClass: (elementName, contentDevice) => {
      const _contentDevice = contentDevice || 'Desktop'
      const isMobileContent = _contentDevice.toLowerCase() === 'mobile'
      const isMobile = isMobileContent && (context.$device.isMobile || context.route.query.device === 'mobile')
      let classNames = columns[elementName] || []
      if (isMobile) {
        classNames = columnsMobile[elementName] || []
      }
      return classNames
    },

    /**
     * Get element resource
     * @param {string} key
     * @param {array} data
     */
    getElementResource: (key, data) => {
      key = key || ''
      // const data = context.store.state.contents.content.renderResource
      const resource = data.filter((r) => {
        return r.key === key
      })
      if (resource.length) { return resource[0] }
      return null
    },

    /**
     * Get element class
     * @param {object} elementStyle
     */
    getElementClass: (elementStyle) => {
      elementStyle = elementStyle || {}
      let classNames = ''
      if ('custom_class' in elementStyle) {
        if (/,/i.test(elementStyle.custom_class)) {
          classNames = elementStyle.custom_class.split(',').join(' ')
        } else {
          classNames = elementStyle.custom_class || ''
        }
      }
      return classNames
    },

    /**
     * Get element style
     * @param {object} elementStyle
     */
    getElementStyle: (elementStyle) => {
      elementStyle = elementStyle || {}
      const style = []
      // background image
      if ('background_img' in elementStyle && elementStyle.background_img) {
        // const imageSource = elementStyle.background_img.background_source.images || []
        let imageSource = []
        if (elementStyle.background_img && elementStyle.background_img.background_source) {
          imageSource = elementStyle.background_img.background_source.images || []
        }
        const defaultImage = imageSource.filter((r) => { return r.size === 'default' })[0] || {}
        style.push(`background-image: url('${defaultImage.url}')`)
        style.push('background-repeat: no-repeat')
      }
      // background position
      if ('background_position' in elementStyle && elementStyle.background_position) {
        style.push(`background-position: ${elementStyle.background_position}`)
      }
      // background size
      if ('background_size' in elementStyle && elementStyle.background_size) {
        style.push(`background-size: ${elementStyle.background_size}`)
      }
      // background color
      if ('background_color' in elementStyle && elementStyle.background_color) {
        style.push(`background-color: ${elementStyle.background_color}`)
      }
      // padding left
      if ('padding_left' in elementStyle && elementStyle.padding_left) {
        style.push(`padding-left: ${elementStyle.padding_left}`)
      }
      // padding right
      if ('padding_right' in elementStyle && elementStyle.padding_right) {
        style.push(`padding-right: ${elementStyle.padding_right}`)
      }
      // padding top
      if ('padding_top' in elementStyle && elementStyle.padding_top) {
        style.push(`padding-top: ${elementStyle.padding_top}`)
      }
      // padding bottom
      if ('padding_bottom' in elementStyle && elementStyle.padding_bottom) {
        style.push(`padding-bottom: ${elementStyle.padding_bottom}`)
      }
      // display
      if ('display' in elementStyle && elementStyle.display) {
        style.push(`display: ${elementStyle.display}`)
      }
      // display
      if ('color' in elementStyle && elementStyle.color) {
        style.push(`color: ${elementStyle.color}`)
      }
      return style.join(';')
    }
  }

  inject('dynamicContent', dynamicContent)
  context.$dynamicContent = dynamicContent
}
