const state = () => ({
  podcast_playlist_landing: {},
  podcast_playlist_detail: {}
})

const mutations = {
  setPodcastPlaylistLanding (state, data) {
    state.podcast_playlist_landing = data
  },
  setPodcastPlaylistDetail (state, data) {
    state.podcast_playlist_detail = data
  }

}

const actions = {
/*
    ** get data podcastplaylistlanding
  */
  async getPodcastPlaylistLanding ({ commit }, payload) {
    const url = process.env.API_ENDPOINT + 'podcast-playlist/summary'
    await this.$axios
      .get(url, {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageUuid: payload.pageUuid,
          pageIndex: payload.pageIndex || 0,
          pageSize: payload.pageSize || 9
        }
      })
      .then(res => commit('setPodcastPlaylistLanding', res ? res.data : {}))
      .catch(err => err)
  },
  /*
    ** get data podcastplaylistdetail
  */
  async getPodcastPlaylistDetail ({ commit }, payload) {
    let url = process.env.API_ENDPOINT + 'podcast-playlist/podcasts'
    if (payload.mode === 'preview') {
      url = process.env.API_ENDPOINT + 'podcast-playlist/podcasts-preview'
    }
    await this.$axios
      .get(url, {
        params: {
          channel: process.env.WEB_CHANNEL,
          lang: this.$i18n.locale,
          pageUuid: payload.pageUuid,
          pageIndex: payload.pageIndex,
          pageSize: payload.pageSize
        }
      })
      .then(res => commit('setPodcastPlaylistDetail', res.data))
      .catch(err => err)
  }
}

const getters = {
  getPodcastPlaylistLandingData: (state) => {
    return state.podcast_playlist_landing
  },
  getPodcastPlaylistDetailData: (state) => {
    return state.podcast_playlist_detail
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
