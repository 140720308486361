//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({ content: 'contents/getContentData' }),
    title () {
      return `${(this.pageSeoData || {}).title || this.content.title} - ${this.content.websiteInfo ? this.content.websiteInfo.websiteName : ''}`
    }
  },
  head () {
    const i18nSeo = this.$nuxtI18nSeo()
    return {
      title: this.title,
      htmlAttrs: {
        ...i18nSeo.htmlAttrs
      },
      meta: [
        ...i18nSeo.meta
      ]
    }
  }
}
