//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Menu',
  props: {
    menuName: {
      type: String,
      default: () => ''
    },
    menuList: {
      type: Array,
      required: true
    }
  },
  serverCacheKey: props => 'Menu::' + props.menuName + '_' + props.menuList.map((r) => { return r.name + '_' + r.url + '_' + r.openNewTab }).join('_'),
  methods: {
    handleClickTracking (data) {
      const eventTracking = data.event_tracking || {}
      const isEtk = eventTracking.is_etk || false
      const dataTeamName = '' // eventTracking.data_team_name // Data Category(Team)
      const dataAction = this.menuName ? `Menu ${this.menuName}` : '' // Data Action(Section)
      const label = data.name
      if (isEtk) {
        this.clickTracking({
          title: label,
          sectionName: dataAction,
          teamName: dataTeamName
        })
      }
    }
    // handleClickTracking (data) {
    //   if (!data.evtTeamName || !data.url) { return false }
    //   const dataTeamName = data.evtTeamName // Data Category(Team)
    //   const dataAction = this.menuName ? `Menu ${this.menuName}` : '' // Data Action(Section)
    //   const label = data.name
    //   this.clickTracking({
    //     title: label,
    //     sectionName: dataAction,
    //     teamName: dataTeamName
    //   })
    // }
  }
}
