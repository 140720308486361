import { render, staticRenderFns } from "./ModalSearch.vue?vue&type=template&id=fa0d3da6&"
import script from "./ModalSearch.vue?vue&type=script&lang=js&"
export * from "./ModalSearch.vue?vue&type=script&lang=js&"
import style0 from "./ModalSearch.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonClose: require('/usr/src/set-nuxt/components/Button/ButtonClose.vue').default,Search: require('/usr/src/set-nuxt/components/Search/Search.vue').default})
