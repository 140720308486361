import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import ElementSnackBar from '~/components/Elements/ElementSnackBar.vue'
import { pageType } from '~/resources'
Vue.mixin({
  methods: {
    // -------------- BookMark ------------------
    /**
     * @param {boolean} active active
     * @param {int} index index
     * @param {string} pageUuid pageUuid
     * @param {string} variable variable
     * @param {function} callback callback function
     */
    isBookmark (isBookmark) {
      if (this.permissionUser()) {
        this.$store.dispatch('handle/setIsBookmarked', !isBookmark)
        this.$cookies.set('contentBookmark-status', !isBookmark)
        return !isBookmark
      } else {
        return false
      }
    },

    clearIsBookmark () {
      this.$store.commit('handle/setIsBooking', { pageUuid: '', index: 0, status: null, pageUuidArray: [] })
    },

    /**
     * ssr cache, keepBoomarkStatus used to update bookmark status
     * @param {*} data
     */
    keepBoomarkStatus (data) {
      const bookmarks = this.$cookies.get('bookmarks') || []
      const index = bookmarks.findIndex(f => f.pageUuid === data.pageUuid)
      if (index !== -1) {
        bookmarks[index].isBookmark = data.isBookmark
      } else {
        bookmarks.push(data)
      }

      const date = new Date()
      date.setTime(date.getTime() + (20 * 1000)) // set expire at 20 seconds
      this.$cookies.set('bookmarks', bookmarks, {
        path: '/',
        maxAge: 20
      })
    },

    handleBookMarkModal (active, index, pageUuid, type, callback) {
      this.$store.commit('handle/setIndex', index)
      this.$store.commit('handle/setType', type)
      this.$store.commit('handle/setBookmark', pageUuid)
      if (typeof callback === 'function') {
        this.$store.commit('handle/setCallback', callback)
      }
      if (active) {
        this.$bvModal.show('modal-unbookmark')
      } else {
        this.$store.commit('handle/setActive', true)
        this.handleBookMark(pageUuid)
      }
    },
    async handleBookMark (pageUuid, index, type) {
      if (this.permissionUser()) {
        let AfterLogin = false
        const contentBookmarkAfterLogin = this.$cookies.get('contentBookmark-afterLogin')
        if (contentBookmarkAfterLogin !== undefined) {
          AfterLogin = contentBookmarkAfterLogin
        }
        const data = {
          BookmarkData: [],
          activityLogData: this.$store.state.profile.activityLogData
        }
        data.BookmarkData = [
          ...data.BookmarkData,
          {
            SetMemberUserRef: this.$authUser.userRef(), // this.$store.state.profile.user.userRef,
            PageUuid: pageUuid,
            IsAfterLogin: AfterLogin
          }
        ]
        let bookmarkResult = {}
        if (type && type === 'ExternalPage') {
          // console.log(type)
          // const extBookmarkResult = await this.$store.dispatch('handle/saveBookMarkExternalPage', pageUuid)
          bookmarkResult = await this.$store.dispatch('handle/saveBookMarkExternalPage', pageUuid)
          this.showFollowBookmarkNoti(bookmarkResult.status, 'bookmark', this.$store.state.handle.isBookmarked)
        } else if (type && type === 'File') {
          // console.log(type)
          // const fileBookmarkResult = await this.$store.dispatch('handle/saveBookMarkFile', pageUuid)
          bookmarkResult = await this.$store.dispatch('handle/saveBookMarkFile', pageUuid)
          this.showFollowBookmarkNoti(bookmarkResult.status, 'bookmark', this.$store.state.handle.isBookmarked)
        } else {
          // console.log('else')
          bookmarkResult = await this.$store.dispatch('handle/saveBookMark', data)
          this.showFollowBookmarkNoti(bookmarkResult.status, 'bookmark', this.$store.state.handle.isBookmarked)
        }
        if (bookmarkResult.status === 200) {
          this.keepBoomarkStatus({
            type,
            pageUuid,
            isBookmark: this.$store.state.handle.isBookmarked
          })
        }

        // **** onClick unBookmark in modal unBookmark ****

        if (typeof this.$store.state.handle.handle.callback === 'function') {
          const index = cloneDeep(this.$store.state.handle.handle.index)
          const active = cloneDeep(this.$store.state.handle.handle.active)
          this.$store.state.handle.handle.callback(pageUuid, active, index)
        }
        this.$cookies.set('contentBookmark', pageUuid)
        this.$cookies.set('contentBookmark-index', index)

        // **** array pageUuid bookmark for render homepage content while cache ssr

        if (this.$cookies.get('contentBookmark-array') !== undefined && !AfterLogin) {
          let array = this.$cookies.get('contentBookmark-array')
          array = [...array, { pageUuid, index }]
          this.$cookies.set('contentBookmark-array', array)
        } else {
          this.$cookies.set('contentBookmark-array', [{ pageUuid, index }])
        }
      } else {
        if (type) {
          this.$cookies.set('contentBookmark-type', type)
        }
        this.$cookies.set('contentBookmark', pageUuid)
        this.$cookies.set('contentBookmark-index', index)
        this.$cookies.set('contentBookmark-array', [{ pageUuid, index }])
        this.$cookies.set('contentBookmark-afterLogin', true)
        this.$cookies.set('url', window.location.href)
        window.location.replace(this.$authUser.login())
      }
    },

    // **** get pageUuid Bookmark after login ****

    getBookmark () {
      const contentBookmarkAfterLogin = this.$cookies.get('contentBookmark-afterLogin')
      if (this.permissionUser()) {
        const contentBookmarkId = this.$cookies.get('contentBookmark')
        const contentBookmarkIndex = this.$cookies.get('contentBookmark-index')
        const contentBookmarkType = this.$cookies.get('contentBookmark-type')
        const contentBookmarkStatus = this.$cookies.get('contentBookmark-status')
        const contentBookmarkArray = this.$cookies.get('contentBookmark-array')
        if (contentBookmarkId !== undefined && contentBookmarkIndex !== undefined) {
          this.$store.commit('handle/setIsBooking', {
            pageUuid: contentBookmarkId,
            status: contentBookmarkStatus,
            index: contentBookmarkIndex,
            pageUuidArray: contentBookmarkArray
          })
        }
        if (contentBookmarkId !== undefined && contentBookmarkAfterLogin && process.server) {
          this.handleBookMark(contentBookmarkId, contentBookmarkIndex, contentBookmarkType)
        }
        this.$cookies.remove('contentBookmark')
        this.$cookies.remove('contentBookmark-index')
        this.$cookies.remove('contentBookmark-type')
        this.$cookies.remove('contentBookmark-status')
        this.$cookies.remove('contentBookmark-array')
        this.$cookies.remove('contentBookmark-afterLogin')
      }
    },

    // ---------------- Follow ----------------------
    /**
     * @param {boolean} active following
     * @param {int} index index
     * @param {Array} ArrayContent ArrayFollowUuid
     * @param {string} variable variable
     * @param {function} callback callback function
     */

    isFollow (isFollow) {
      if (this.permissionUser()) {
        this.$store.dispatch('handle/setIsFollowed', !isFollow)

        return !isFollow
      } else {
        return false
      }
    },

    clearIsFollow () {
      this.$store.commit('handle/setIsFollowing', { pageUuid: [], index: 0 })
    },

    handleFollowModal (active, index, ArrayContent, callback) {
      this.$store.commit('handle/setIndex', index)
      this.$store.commit('handle/setFollow', ArrayContent)
      if (typeof callback === 'function') {
        this.$store.commit('handle/setCallback', callback)
      }
      if (active) {
        this.$bvModal.show('modal-unfollower')
      } else {
        this.$store.commit('handle/setActive', true)
        this.handleFollow(ArrayContent)
      }
    },

    async handleFollow (ArrayContent, isMulti = false, index) {
      if (this.permissionUser()) {
        let AfterLogin = false
        const contentFollowAfterLogin = this.$cookies.get('contentFollow-afterLogin')
        if (contentFollowAfterLogin !== undefined) {
          AfterLogin = contentFollowAfterLogin
        }
        const data = {
          FollowGroupData: [],
          activityLogData: this.$store.state.profile.activityLogData
        }
        data.FollowGroupData = ArrayContent.map((v) => {
          return {
            SetMemberUserRef: this.$authUser.userRef(), // this.$store.state.profile.user.userRef,
            PageUuid: v,
            IsAfterLogin: AfterLogin
          }
        })
        this.$cookies.remove('contentFollow-afterLogin')
        const followResult = await this.$store.dispatch('handle/saveFollow', data)

        if (isMulti) {
          this.showFollowBookmarkNoti(followResult.status, 'follow', null, true)
        } else if (ArrayContent.length !== 0) {
          this.showFollowBookmarkNoti(followResult.status, 'follow', this.$store.state.handle.isFollowed, false)
        }

        // **** onClick unFollow in modal unFollow ****

        if (typeof this.$store.state.handle.handle.callback === 'function') {
          const index = cloneDeep(this.$store.state.handle.handle.index)
          const active = cloneDeep(this.$store.state.handle.handle.active)
          this.$store.state.handle.handle.callback(ArrayContent, active, index)
        }
      } else {
        this.$cookies.set('contentFollow', ArrayContent)
        this.$cookies.set('contentFollow-index', index)
        this.$cookies.set('contentFollow-afterLogin', true)
        this.$cookies.set('url', window.location.href)
        window.location.replace(this.$authUser.login())
      }
    },

    // **** get pageUuid Follow after login ****
    getFollowGroup () {
      if (this.permissionUser()) {
        const contentFollowId = this.$cookies.get('contentFollow')
        const contentFollowIndex = this.$cookies.get('contentFollow-index')
        if (contentFollowId !== undefined && contentFollowIndex !== undefined) {
          this.$store.commit('handle/setIsFollowing', {
            pageUuid: contentFollowId,
            index: contentFollowIndex
          })
        }
        if (contentFollowId !== undefined && process.server) {
          this.handleFollow(contentFollowId)
        }
        this.$cookies.remove('contentFollow')
        this.$cookies.remove('contentFollow-index')
      }
    },

    // **** fix bug open new tab Safari ****

    openTab (url, target) {
      // Create link in memory
      const a = window.document.createElement('a')
      a.target = target || '_blank'
      a.href = url

      // Dispatch fake click
      const e = window.document.createEvent('MouseEvents')
      e.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      a.dispatchEvent(e)
    },

    showFollowBookmarkNoti (reqStatus, type, isFollowBookmark, multiplefollow) {
      if (reqStatus === 200 && type === 'bookmark' && (isFollowBookmark != null && isFollowBookmark === false)) {
        // show toast
        let bookmarkCounter = this.$store.state.handle.bookmarkCount
        this.$bvToast.toast(this.snackbarBodyBuilder('unbookmark', 'bookmark-snackbar-failed_' + bookmarkCounter), {
          id: 'bookmark-snackbar-failed_' + bookmarkCounter,
          headerClass: 'd-none',
          bodyClass: 'notification-snackbar-failed',
          toastClass: 'notification-snackbar',
          toaster: 'b-toaster-bottom-left',
          appendToast: true,
          autoHideDelay: 5000
        })

        // add bookmark count
        bookmarkCounter += 1
        this.$store.dispatch('handle/addBookmarkCount', bookmarkCounter)
      } else if (reqStatus === 200 && type === 'bookmark' && (isFollowBookmark != null && isFollowBookmark === true)) {
        // show toast
        let bookmarkCounter = this.$store.state.handle.bookmarkCount
        this.$bvToast.toast(this.snackbarBodyBuilder('bookmark', 'bookmark-snackbar-success_' + bookmarkCounter), {
          id: 'bookmark-snackbar-success_' + bookmarkCounter,
          headerClass: 'd-none',
          bodyClass: 'notification-snackbar-success',
          toastClass: 'notification-snackbar',
          toaster: 'b-toaster-bottom-left',
          appendToast: true,
          autoHideDelay: 5000
        })

        // add bookmark count
        bookmarkCounter += 1
        this.$store.dispatch('handle/addBookmarkCount', bookmarkCounter)
      } else if (reqStatus === 200 && type === 'follow' && (isFollowBookmark != null && isFollowBookmark === false)) {
        // show toast
        let followCounter = this.$store.state.handle.followCount
        this.$bvToast.toast(this.snackbarBodyBuilder('unfollow', 'follow-snackbar-failed_' + followCounter), {
          id: 'follow-snackbar-failed_' + followCounter,
          headerClass: 'd-none',
          bodyClass: 'notification-snackbar-failed',
          toastClass: 'notification-snackbar',
          toaster: 'b-toaster-bottom-left',
          appendToast: true,
          autoHideDelay: 5000
        })

        // add bookmark count
        followCounter += 1
        this.$store.dispatch('handle/addFollowCount', followCounter)
      } else if (reqStatus === 200 && type === 'follow' && (isFollowBookmark != null && isFollowBookmark === true)) {
        // show toast
        let followCounter = this.$store.state.handle.followCount
        this.$bvToast.toast(this.snackbarBodyBuilder('follow', 'follow-snackbar-success_' + followCounter), {
          id: 'follow-snackbar-success_' + followCounter,
          headerClass: 'd-none',
          bodyClass: 'notification-snackbar-success',
          toastClass: 'notification-snackbar',
          toaster: 'b-toaster-bottom-left',
          appendToast: true,
          autoHideDelay: 5000
        })

        // add bookmark count
        followCounter += 1
        this.$store.dispatch('handle/addFollowCount', followCounter)
      } else if (reqStatus === 200 && type === 'follow' && multiplefollow === true) {
        // show toast
        let followCounter = this.$store.state.handle.followCount
        this.$bvToast.toast(this.snackbarBodyBuilder('follow', 'follow-snackbar-success_' + followCounter), {
          id: 'follow-snackbar-success_' + followCounter,
          headerClass: 'd-none',
          bodyClass: 'notification-snackbar-success',
          toastClass: 'notification-snackbar',
          toaster: 'b-toaster-bottom-left',
          appendToast: true,
          autoHideDelay: 5000
        })

        // add bookmark count
        followCounter += 1
        this.$store.dispatch('handle/addFollowCount', followCounter)
      } else if (reqStatus !== 200) {
        // show toast
        let followBookmarkCounter = this.$store.state.handle.followBookmarkFailedCount
        this.$bvToast.show('action-snackbar-_' + followBookmarkCounter)
        this.$bvToast.toast(this.snackbarBodyBuilder('actionfailed', 'action-snackbar-_' + followBookmarkCounter), {
          id: 'action-snackbar-_' + followBookmarkCounter,
          headerClass: 'd-none',
          bodyClass: 'notification-snackbar-failed',
          toastClass: 'notification-snackbar',
          toaster: 'b-toaster-bottom-left',
          appendToast: true,
          autoHideDelay: 5000
        })

        // add bookmark count
        followBookmarkCounter += 1
        this.$store.dispatch('handle/setCountBookmark', followBookmarkCounter)
      }
    },
    snackbarBodyBuilder (type, id, message) {
      const snackbarBody = this.$createElement(ElementSnackBar, { props: { toastType: type, toastId: id, message } })

      return snackbarBody
    },
    linkContentTarget (pType) {
      if (pType === pageType.EBook || pType === 'Ebook' || pType === pageType.ELearning) {
        return '_blank'
      } else {
        return '_self'
      }
    }
  }
})
