export default {
  computed: {
    /**
     * Truehits script
     */
    truehitsScript () {
      if (this.$store.state.contents.content && this.$store.state.contents.content.advanced) {
        if (this.$store.state.contents.content.advanced.trueHitsId) {
          const routeName = this.$route.fullPath.split('/')
          let pageContent = ''
          for (let i = 1; i < routeName.length; i++) {
            pageContent += routeName[i] + ' - '
          }
          return [
            {
              body: true,
              type: 'text/javascript',
              innerHTML: `__th_page="${this.trackingPageName || (pageContent + this.$store.state.contents.content.title)}";`
            },
            {
              body: true,
              type: 'text/javascript',
              innerHTML: `
              (function() {
                var ga1 = document.createElement('script'); ga1.type = 'text/javascript';
                ga1.async = true;
                ga1.src = "//lvs.truehits.in.th/dataa/${this.$store.state.contents.content.advanced.trueHitsId}.js";
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga1, s);
              })();
              `
            }
          ]
        }
      }
      return []
    }
  }
}
