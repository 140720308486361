import https from 'https'
import http from 'http'
// import { cacheAdapterEnhancer } from 'axios-extensions'
// import LRU from 'lru-cache'
// const MAX_AGE = 1000 * 60 * 5

const WEB_CHANNEL = process.env.WEB_CHANNEL

export default function ({ $axios, ssrContext, app }) {
  $axios.defaults.httpAgent = new http.Agent({ keepAlive: true })
  $axios.defaults.httpsAgent = new https.Agent({
    keepAlive: true,
    rejectUnauthorized: process.env.HTTPS_AGENT === 'true'
  })

  // $axios.setHeader('x-api-key', process.env.X_API_KEY)
  $axios.setHeader('Access-Control-Allow-Origin', '*')
  $axios.setHeader('Access-Control-Allow-Headers', 'Authorization')

  // const defaultCache = process.server
  //   ? ssrContext.$axCache
  //   : new LRU({ maxAge: MAX_AGE })

  // const defaults = $axios.defaults
  // // https://github.com/kuitos/axios-extensions
  // defaults.adapter = cacheAdapterEnhancer(defaults.adapter, {
  //   enabledByDefault: false,
  //   cacheFlag: 'useCache',
  //   defaultCache
  // })

  $axios.onRequest((config) => {
    // request headers website channel: WEB_SETINVESTNOW
    config.headers['x-channel'] = WEB_CHANNEL
    // request headers language
    config.headers['x-language'] = app.i18n.locale
    // request headers device
    const device = app.$device.isMobile ? 'Mobile' : 'Desktop'
    config.headers['x-device'] = device

    const uref = app.$cookies.get('uref') || ''
    const accessToken = app.$cookies.get('id') || ''
    if (accessToken && uref) {
      config.headers['x-userid'] = uref
    }

    // request client uuid
    const clientUuid = app.store.state.utility.clientUuid
    if (clientUuid) {
      config.headers['x-client-uuid'] = clientUuid
    }

    // request page uuid
    const content = app.store.state.contents.content || {}
    const pageUuid = content.pageUuid
    if (pageUuid) {
      config.headers['x-page-uuid'] = pageUuid
    }
  })
  $axios.interceptors.request.use((x) => {
    x.meta = x.meta || {}
    x.meta.requestStartedAt = new Date().getTime()
    return x
  })
  $axios.interceptors.response.use((x) => {
    if (process.server) {
      const { url, device } = x.config.params || {}
      console.log('🚀 ~ x.config.params:', x.config.params)
      console.log('[Response]API - Content URL:', url)
      console.log('[Response]API - Device:', device)
      console.log(`Execution time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
    }
    return x
  }, (x) => {
    if (process.server) {
      console.error(`Error response time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
      console.log('Error details : ', x)
    }
    throw x
  })
}
