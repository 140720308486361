const isInvalidPath = (path) => {
  const splitPath = path.split('/')
  let inValid = false
  if (splitPath.length >= 3) {
    inValid = /^\?/i.test(splitPath[2]) || !splitPath[2]
  }
  return inValid
}
function isFunctionalPage (routeName) {
  if (!routeName) { return false }
  const isMatch = routeName.includes('authorization_') ||
  routeName.includes('error-503_')
  return isMatch
}

export default async function ({
  store,
  route,
  env,
  query,
  redirect,
  app,
  $axios,
  req,
  error
}) {
  // handle invalid path | example: /th/?fbclid=
  const invalidPath = isInvalidPath(route.fullPath)
  if (invalidPath === true) {
    redirect('/')
  }

  // Some pages do not require content data
  const _isFunctionalPage = isFunctionalPage(route.name)
  if (_isFunctionalPage) { return }

  // cookie options
  const cookieOption = {
    path: '/'
    // httpOnly: true
    // domain: env.HOST,
    // secure: env.HTTPS_AGENT === true
  }
  // Remove member cookie
  const removeMemberCookie = () => {
    app.$cookies.remove('uref', cookieOption)
    app.$cookies.remove('access_token', cookieOption)
    app.$cookies.remove('bookmarks')
  }

  try {
    let user = null
    let url = null

    // set client id
    await app.$helpers.setClientUuid()

    // **** Auth Login ****
    if (process.server) {
      // **** set activityLog data ****
      const activityLogData = {
        ipAddress: req.connection.remoteAddress || req.socket.remoteAddress,
        Browser: app.$ua.browser(),
        BrowserVersion: app.$ua.browserVersion(),
        ChannelCode: process.env.WEB_CHANNEL
      }
      store.commit('profile/setActivityLogData', activityLogData)

      if (route.query.token !== undefined) {
        // **** Login FirstTime ****
        if (app.$cookies.get('url') !== undefined) {
          // Redirect to Latest Page
          url = app.$cookies.get('url')
          await app.$cookies.remove('url')
        }
        app.$cookies.set('access_token', route.query.token, {
          ...cookieOption,
          expires: new Date(Number(new Date()) + 30 * 1000 * 60 * 60 * 24)
        })
        user = await store.dispatch('profile/getUserStatus', route.query.token)
        if (user) {
          app.$cookies.set('uref', user.userRef, {
            ...cookieOption,
            expires: new Date(Number(new Date()) + 30 * 1000 * 60 * 60 * 24)
          })
          await store.dispatch('profile/addUser', {
            ...user,
            token: route.query.token
          })
          app.$cookies.set('isMember', true)
        } else {
          removeMemberCookie()
          app.$cookies.set('isMember', false)
        }

        // **** Redirect to Latest Page ****
        redirect(url)
      } else if (app.$cookies.get('access_token') !== undefined) {
        // **** When Reload Page ****
        if (app.$cookies.get('isMember')) {
          user = await store.dispatch(
            'profile/getUserStatus',
            app.$cookies.get('access_token')
          )
          if (user) {
            app.$cookies.set('uref', user.userRef, cookieOption)
            await store.dispatch('profile/addUser', {
              ...user,
              token: app.$cookies.get('access_token')
            })
            app.$cookies.set('isMember', true)
          } else {
            removeMemberCookie()
            app.$cookies.set('isMember', false)
          }
        } else {
          removeMemberCookie()
        }
        await store.dispatch('contents/getContent', route)
      } else {
        removeMemberCookie()
        app.$cookies.set('isMember', false)
        await store.dispatch('contents/getContent', route)
      }
    } else {
      if (store.state.profile.user) {
        user = await store.dispatch(
          'profile/getUserStatus',
          escape(store.state.profile.user.token)
        )
        if (!user) {
          removeMemberCookie()
          app.$cookies.set('isMember', false)
          // if (/^profile-contents_/i.test(route.name)) {
          //   redirect(`/${app.i18n.locale}/home`)
          //   location.reload()
          // }
        }
      }
      await store.dispatch('contents/getContent', route)
    }
  } finally {
  }
}
