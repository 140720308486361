import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({ seoDataContent: 'contents/getContentData' }),
    setMetaDataWebsite () {
      if (Object.keys(this.seoDataContent).length && this.seoDataContent.advanced && this.seoDataContent.advanced.customMetaTags) {
        const meta = this.seoDataContent.advanced.customMetaTags.map((r) => {
          const metaProps = {}
          metaProps[r.metaType] = r.name
          metaProps.content = r.content
          return metaProps
        })
        return meta
      }
      return []
    },
    setAddIconToHomeScreen () {
      if (Object.keys(this.seoDataContent).length) {
        const HomeScreen = [
          // for chrom on android
          { name: 'mobile-web-app-capable', content: 'yes' },
          // for safari on ios
          { name: 'apple-mobile-web-app-capable', content: 'yes' },
          { name: 'apple-mobile-web-app-status-bar-style', content: 'black' }
        ]
        if (this.seoDataContent.advanced && this.seoDataContent.advanced.appTitle) {
          HomeScreen.push({ name: 'application-name', content: this.seoDataContent.advanced.appTitle })
          HomeScreen.push({ name: 'apple-mobile-web-app-title', content: this.seoDataContent.advanced.appTitle })
        }
        return HomeScreen
      }
      return []
    },
    pageSeoData () {
      const dynamicPageData = {
        title: this.seoDataContent.title,
        description: this.seoDataContent.description,
        keywords: this.seoDataContent.keywords
      }
      const pageData = this.specificPageData || dynamicPageData
      return pageData
    },
    getMetaDataWebsite () {
      if (Object.keys(this.seoDataContent).length) {
        const pageSeo = this.pageSeoData
        const websiteUrl = (this.seoDataContent.websiteInfo || { websiteUrl: this.seoDataContent.websiteUrl } /* get websiteUrl from welcomepage */).websiteUrl
        const domainName = this.$helpers.getDomainFromURL(websiteUrl)
        let socialThumbnail = this.seoDataContent.socialThumbnail
        if (pageSeo.socialThumbnail) {
          socialThumbnail = `${this.seoDataContent.websiteInfo.websiteUrl}${pageSeo.socialThumbnail}`
        }
        // META Twitter meta
        const twitterMeta = [
          { name: 'twitter:card', content: process.env.TWITTER_CARD },
          { name: 'twitter:site', content: process.env.TWITTER_SITE },
          { name: 'twitter:creator', content: process.env.TWITTER_CREATOR },
          { name: 'twitter:title', content: pageSeo.title },
          { name: 'twitter:description', content: pageSeo.description },
          { name: 'twitter:image:src', content: socialThumbnail },
          { name: 'twitter:domain', content: domainName }
        ]
        // META Open graph
        const ogMeta = [
          { property: 'og:type', content: process.env.OG_TYPE },
          { property: 'og:title', content: pageSeo.title },
          { property: 'og:description', content: pageSeo.description },
          { property: 'og:image', content: socialThumbnail },
          { property: 'og:url', content: this.$route.fullPath }
        ]
        const meta = [
          {
            hid: 'description',
            name: 'description',
            content: pageSeo.description
          },
          { name: 'keywords', content: pageSeo.keywords },
          ...ogMeta,
          ...twitterMeta,
          ...this.setMetaDataWebsite,
          ...this.setAddIconToHomeScreen
        ]

        // App suggestion
        if (this.seoDataContent.appSuggest) {
          meta.push(
            { name: 'apple-itunes-app', content: this.seoDataContent.appSuggest.appStore },
            { name: 'google-play-app', content: this.seoDataContent.appSuggest.playStore }
          )
        }
        return meta
      }
      return []
    },
    setLinkWebsite () {
      const LinkData = []
      if (Object.keys(this.seoDataContent).length && this.seoDataContent.advanced && this.seoDataContent.advanced.appIcon) {
        LinkData.push(
          { rel: 'apple-touch-icon', href: this.seoDataContent.advanced.appIcon },
          { rel: 'android-touch-icon', href: this.seoDataContent.advanced.appIcon }
        )
      }
      // set fav icon
      if (Object.keys(this.seoDataContent).length && this.seoDataContent.fav) {
        LinkData.push({ rel: 'icon', type: 'image/x-icon', href: this.seoDataContent.fav })
      }
      return LinkData
    },
    getEmbedScriptDataWebsite () {
      if (Object.keys(this.seoDataContent).length && this.seoDataContent.embedScriptWebsite) {
        const dataEmbedWebsiteHead = decodeURI(this.seoDataContent.embedScriptWebsite.head)
        const dataEmbedWebsiteBody = decodeURI(this.seoDataContent.embedScriptWebsite.body)
        const scriptWebsiteHead = this.setDataEmbedScript(dataEmbedWebsiteHead)
        const scriptWebsiteBody = this.setDataEmbedScript(dataEmbedWebsiteBody, true)
        return [...scriptWebsiteHead, ...scriptWebsiteBody]
      }
      return []
    },
    getEmbedScriptDataPage () {
      let dataEmbedPageHead = ''
      let dataEmbedPageBody = ''
      if (Object.keys(this.seoDataContent).length && this.seoDataContent.embedScriptPage) {
        dataEmbedPageHead = decodeURI(this.seoDataContent.embedScriptPage.head)
        dataEmbedPageBody = decodeURI(this.seoDataContent.embedScriptPage.body)
        return [...this.setDataEmbedScript(dataEmbedPageHead), ...this.setDataEmbedScript(dataEmbedPageBody, true)]
      }
      return []
    },
    getStyleCssWebsite () {
      if (Object.keys(this.seoDataContent).length && this.seoDataContent.customCss) {
        return this.setDataStyleCss(decodeURI(this.seoDataContent.customCss))
      }
      return []
    },
    isGrayscale () {
      if (Object.keys(this.seoDataContent).length) {
        if (this.seoDataContent.advanced && this.seoDataContent.advanced.isGrayscale === true) {
          return 'gray-scale-mode'
        } else {
          return ''
        }
      }
      return ''
    }
  },
  methods: {
    setDataEmbedScript (dataEmbedWebsite, bodySet = false) {
      const res = dataEmbedWebsite.match(/<script.*?>([\s\S]*?)<\/script>/g)
      let scriptTag = ''
      let attributeArray = []
      let scriptData = {}
      const scriptArray = []
      if (res) {
        res.forEach(function (item) {
          scriptData = {}
          scriptData.innerHTML = item.replace(/<\/?script.*?>/g, '')
          scriptTag = item.match(/<script.*?>/g)
          attributeArray = scriptTag.join().match(/([a-zA-z]+(\s?)+=(\s?)+("|')(.*?)("|'))|defer|async/g)
          if (attributeArray != null) {
            attributeArray.forEach(function (itemAttr) {
              if (itemAttr === 'defer' || itemAttr === 'async') {
                scriptData[itemAttr] = true
              } else {
                scriptData[itemAttr.split('=')[0]] = itemAttr.split('=')[1].replace(/"|'/g, '')
              }
            })
          }
          if (bodySet === true) {
            scriptData.body = true
          }
          scriptArray.push(scriptData)
          scriptData = {}
        })
      }
      return scriptArray
    },
    setDataStyleCss (StyleCss) {
      const res = StyleCss.match(/<style.*?>([\s\S]*?)<\/style>/g)
      let styleData = {}
      let styleTag = []
      let attributeArray = []
      const styleArray = []
      if (res) {
        res.forEach(function (item) {
          styleData.cssText = item.replace(/<\/?style.*?>/g, '')
          styleTag = item.match(/<style.*?>/g)
          attributeArray = styleTag.join().match(/([a-zA-z]+(\s?)+=(\s?)+("|')(.*?)("|'))/g)
          if (attributeArray != null) {
            attributeArray.forEach(function (itemAttr) {
              styleData[itemAttr.split('=')[0]] = itemAttr.split('=')[1].replace(/"|'/g, '')
            })
          }
          styleArray.push(styleData)
          styleData = {}
        })
      }
      return styleArray
    }
  }
}
