//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PopularKeyword',
  props: {
    keyword: {
      type: Array,
      default: () => []
    }
  },
  // serverCacheKey: props => 'PopularKeyword::' + props.keyword.map((r) => { return r.text + '_' + r.value }).join('_'),
  mounted () {
  },
  methods: {
    handleSearch (item) {
      this.handleClickTracking(item)
      this.$bvModal.hide('modal-search')
    },
    handleClickTracking (item) {
      this.$gtmTracking.pushDataLayerSiteSearch(item.text, 'search_box_keyword')
      this.clickTracking({
        title: item.text,
        sectionName: this.$t('search-popular'),
        url: item.value,
        teamName: '' // process.env.GTM_EVENT_CATEGORY_TEAM
      })
    }
  }
}
