import isFunctionalPage from '~/helpers/functionalPage'
/**
 * State
 */
const state = () => ({
  contentDataLayer: {
    currentPath: ''
  }
})

/**
 * Mutations
 */
const mutations = {
  setContentDataLayerCurrentPath (state, data) {
    state.contentDataLayer.currentPath = data
  }
}

const actions = {
  async nuxtServerInit ({ commit, dispatch }, { route }) {
    const _isFunctionalPage = await isFunctionalPage(route.name)
    if (!_isFunctionalPage) {
      await this.$authUser.authCode()
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
