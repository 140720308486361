import Vue from 'vue'
import { mapGetters } from 'vuex'
import { spRouteNamePattern } from '~/resources'
Vue.mixin({
  computed: {
    ...mapGetters({ spDataContent: 'contents/getContentData' }),
    isKnowledgePage () {
      const routeName = this.$route.name
      const isknowledge = spRouteNamePattern.knowledge.test(routeName) ||
      spRouteNamePattern.knowledgeArticle.test(routeName) ||
      spRouteNamePattern.knowledgeVideo.test(routeName) ||
      spRouteNamePattern.knowledgeEBook.test(routeName) ||
      spRouteNamePattern.knowledgeElearning.test(routeName) ||
      spRouteNamePattern.knowledgePodcast.test(routeName)
      return isknowledge
    },
    specificPageData () {
      const routeName = this.$route.name
      const query = this.$route.query || {}
      if (spRouteNamePattern.WelcomePage.test(routeName)) {
        return {
          title: this.$t('pages.welcomePage.title'),
          description: this.$t('pages.welcomePage.description'),
          keywords: this.$t('pages.welcomePage.keywords')
        }
      }
      if (spRouteNamePattern.home.test(routeName)) {
        return {
          title: this.$t('pages.home.title'),
          description: this.$t('pages.home.description'),
          keywords: this.spDataContent.keywords
        }
      }
      if (spRouteNamePattern.search.test(routeName)) {
        return {
          title: this.$t('pages.search'),
          description: this.spDataContent.description,
          keywords: this.spDataContent.keywords
        }
      }
      if (spRouteNamePattern.profile.test(routeName)) {
        const type = query.type
        let title = this.$t('pages.bookmark')
        if (type && type.toLowerCase() === 'follow') {
          title = this.$t('pages.follow')
        }
        return {
          title,
          description: this.spDataContent.description,
          keywords: this.spDataContent.keywords
        }
      }
      if (this.isKnowledgePage) {
        let description = this.$t('pages.knowledge.description')
        if (spRouteNamePattern.knowledgeArticle.test(routeName)) {
          description = this.$t('pages.knowledgeArticle.description')
        }
        if (spRouteNamePattern.knowledgeVideo.test(routeName)) {
          description = this.$t('pages.knowledgeVideo.description')
        }
        if (spRouteNamePattern.knowledgePodcast.test(routeName)) {
          description = this.$t('pages.knowledgePodcast.description')
        }
        if (spRouteNamePattern.knowledgeEBook.test(routeName)) {
          description = this.$t('pages.knowledgeEBook.description')
        }
        return {
          title: this.$t('pages.knowledge.title'),
          description,
          keywords: this.$t('pages.knowledge.keywords'),
          socialThumbnail: '/images/thumbnail-knowledge.jpg' // require('@/static/images/thumbnail-knowledge.jpg')
        }
      }
      if (spRouteNamePattern.knowledgeVideoPlayList.test(routeName)) {
        return {
          title: this.$t('pages.videoplaylist.title'),
          description: this.$t('pages.videoplaylist.description'),
          keywords: this.$t('pages.videoplaylist.keywords'),
          socialThumbnail: '/images/thumbnail-videoplaylist.jpg' // require('@/static/images/thumbnail-videoplaylist.jpg')
        }
      }
      if (spRouteNamePattern.knowledgePodcastPlayList.test(routeName)) {
        return {
          title: this.$t('pages.podcastplaylist.title'),
          description: this.$t('pages.podcastplaylist.description'),
          keywords: this.$t('pages.podcastplaylist.keywords'),
          socialThumbnail: '/images/thumbnail-podcastplaylist.jpg' // require('@/static/images/thumbnail-podcastplaylist.jpg')
        }
      }
      if (spRouteNamePattern.glossary.test(routeName)) {
        return {
          title: this.$t('pages.glossary.title'),
          description: this.$t('pages.glossary.description'),
          keywords: this.$t('pages.glossary.keywords'),
          socialThumbnail: '/images/thumbnail-glossary.jpg' // require('@/static/images/thumbnail-glossary.jpg')
        }
      }
      return null
    }
  }
})
