//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModalSearch',
  methods: {
    closeSearch () {
      this.$bvModal.hide('modal-search')
    }
  }
}
