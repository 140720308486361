import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _56126286 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _052757e4 = () => interopDefault(import('../pages/authorization.vue' /* webpackChunkName: "pages/authorization" */))
const _24b2b136 = () => interopDefault(import('../pages/error/index.vue' /* webpackChunkName: "pages/error/index" */))
const _4891b674 = () => interopDefault(import('../pages/glossary/index.vue' /* webpackChunkName: "pages/glossary/index" */))
const _deebc578 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _45c0eaac = () => interopDefault(import('../pages/knowledge/index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _04e7dad7 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _75a1e24d = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _9c1a1cc8 = () => interopDefault(import('../pages/error/404.vue' /* webpackChunkName: "pages/error/404" */))
const _3269d548 = () => interopDefault(import('../pages/error/503.vue' /* webpackChunkName: "pages/error/503" */))
const _5757379d = () => interopDefault(import('../pages/error/oldversion.vue' /* webpackChunkName: "pages/error/oldversion" */))
const _75b2425a = () => interopDefault(import('../pages/knowledge/article/index.vue' /* webpackChunkName: "pages/knowledge/article/index" */))
const _05874d2b = () => interopDefault(import('../pages/knowledge/ebook/index.vue' /* webpackChunkName: "pages/knowledge/ebook/index" */))
const _123efba0 = () => interopDefault(import('../pages/knowledge/elearning/index.vue' /* webpackChunkName: "pages/knowledge/elearning/index" */))
const _03b7db61 = () => interopDefault(import('../pages/knowledge/podcast/index.vue' /* webpackChunkName: "pages/knowledge/podcast/index" */))
const _7c6e18b3 = () => interopDefault(import('../pages/knowledge/podcastplaylist/index.vue' /* webpackChunkName: "pages/knowledge/podcastplaylist/index" */))
const _1daa26d0 = () => interopDefault(import('../pages/knowledge/video/index.vue' /* webpackChunkName: "pages/knowledge/video/index" */))
const _a617fa2c = () => interopDefault(import('../pages/knowledge/videoplaylist/index.vue' /* webpackChunkName: "pages/knowledge/videoplaylist/index" */))
const _3df81000 = () => interopDefault(import('../pages/profile/following-bookmark/index.vue' /* webpackChunkName: "pages/profile/following-bookmark/index" */))
const _1ec3c460 = () => interopDefault(import('../pages/knowledge/article/_.vue' /* webpackChunkName: "pages/knowledge/article/_" */))
const _5eedc290 = () => interopDefault(import('../pages/knowledge/ebook/_.vue' /* webpackChunkName: "pages/knowledge/ebook/_" */))
const _e64760a6 = () => interopDefault(import('../pages/knowledge/elearning/_.vue' /* webpackChunkName: "pages/knowledge/elearning/_" */))
const _7d159024 = () => interopDefault(import('../pages/knowledge/podcast/_.vue' /* webpackChunkName: "pages/knowledge/podcast/_" */))
const _7772ee40 = () => interopDefault(import('../pages/knowledge/podcastplaylist/_.vue' /* webpackChunkName: "pages/knowledge/podcastplaylist/_" */))
const _70d106b6 = () => interopDefault(import('../pages/knowledge/video/_.vue' /* webpackChunkName: "pages/knowledge/video/_" */))
const _65fc8bf7 = () => interopDefault(import('../pages/knowledge/videoplaylist/_.vue' /* webpackChunkName: "pages/knowledge/videoplaylist/_" */))
const _4bfef904 = () => interopDefault(import('../pages/glossary/_slug.vue' /* webpackChunkName: "pages/glossary/_slug" */))
const _464e20c9 = () => interopDefault(import('../pages/preview-form/_slug.vue' /* webpackChunkName: "pages/preview-form/_slug" */))
const _c8092d7a = () => interopDefault(import('../pages/preview/_.vue' /* webpackChunkName: "pages/preview/_" */))
const _230c394a = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _56126286,
    name: "index___en"
  }, {
    path: "/th",
    component: _56126286,
    name: "index___th"
  }, {
    path: "/en/authorization",
    component: _052757e4,
    name: "authorization___en"
  }, {
    path: "/en/error",
    component: _24b2b136,
    name: "error___en"
  }, {
    path: "/en/glossary",
    component: _4891b674,
    name: "glossary___en"
  }, {
    path: "/en/home",
    component: _deebc578,
    name: "home___en"
  }, {
    path: "/en/knowledge",
    component: _45c0eaac,
    name: "knowledge___en"
  }, {
    path: "/en/profile",
    component: _04e7dad7,
    name: "profile___en"
  }, {
    path: "/en/search",
    component: _75a1e24d,
    name: "search___en"
  }, {
    path: "/th/authorization",
    component: _052757e4,
    name: "authorization___th"
  }, {
    path: "/th/error",
    component: _24b2b136,
    name: "error___th"
  }, {
    path: "/th/glossary",
    component: _4891b674,
    name: "glossary___th"
  }, {
    path: "/th/home",
    component: _deebc578,
    name: "home___th"
  }, {
    path: "/th/knowledge",
    component: _45c0eaac,
    name: "knowledge___th"
  }, {
    path: "/th/profile",
    component: _04e7dad7,
    name: "profile___th"
  }, {
    path: "/th/search",
    component: _75a1e24d,
    name: "search___th"
  }, {
    path: "/en/error/404",
    component: _9c1a1cc8,
    name: "error-404___en"
  }, {
    path: "/en/error/503",
    component: _3269d548,
    name: "error-503___en"
  }, {
    path: "/en/error/oldversion",
    component: _5757379d,
    name: "error-oldversion___en"
  }, {
    path: "/en/knowledge/article",
    component: _75b2425a,
    name: "knowledge-article___en"
  }, {
    path: "/en/knowledge/ebook",
    component: _05874d2b,
    name: "knowledge-ebook___en"
  }, {
    path: "/en/knowledge/elearning",
    component: _123efba0,
    name: "knowledge-elearning___en"
  }, {
    path: "/en/knowledge/podcast",
    component: _03b7db61,
    name: "knowledge-podcast___en"
  }, {
    path: "/en/knowledge/podcastplaylist",
    component: _7c6e18b3,
    name: "knowledge-podcastplaylist___en"
  }, {
    path: "/en/knowledge/video",
    component: _1daa26d0,
    name: "knowledge-video___en"
  }, {
    path: "/en/knowledge/videoplaylist",
    component: _a617fa2c,
    name: "knowledge-videoplaylist___en"
  }, {
    path: "/en/profile/following-bookmark",
    component: _3df81000,
    name: "profile-following-bookmark___en"
  }, {
    path: "/th/error/404",
    component: _9c1a1cc8,
    name: "error-404___th"
  }, {
    path: "/th/error/503",
    component: _3269d548,
    name: "error-503___th"
  }, {
    path: "/th/error/oldversion",
    component: _5757379d,
    name: "error-oldversion___th"
  }, {
    path: "/th/knowledge/article",
    component: _75b2425a,
    name: "knowledge-article___th"
  }, {
    path: "/th/knowledge/ebook",
    component: _05874d2b,
    name: "knowledge-ebook___th"
  }, {
    path: "/th/knowledge/elearning",
    component: _123efba0,
    name: "knowledge-elearning___th"
  }, {
    path: "/th/knowledge/podcast",
    component: _03b7db61,
    name: "knowledge-podcast___th"
  }, {
    path: "/th/knowledge/podcastplaylist",
    component: _7c6e18b3,
    name: "knowledge-podcastplaylist___th"
  }, {
    path: "/th/knowledge/video",
    component: _1daa26d0,
    name: "knowledge-video___th"
  }, {
    path: "/th/knowledge/videoplaylist",
    component: _a617fa2c,
    name: "knowledge-videoplaylist___th"
  }, {
    path: "/th/profile/following-bookmark",
    component: _3df81000,
    name: "profile-following-bookmark___th"
  }, {
    path: "/en/knowledge/article/*",
    component: _1ec3c460,
    name: "knowledge-article-all___en"
  }, {
    path: "/th/knowledge/article/*",
    component: _1ec3c460,
    name: "knowledge-article-all___th"
  }, {
    path: "/en/knowledge/ebook/*",
    component: _5eedc290,
    name: "knowledge-ebook-all___en"
  }, {
    path: "/th/knowledge/ebook/*",
    component: _5eedc290,
    name: "knowledge-ebook-all___th"
  }, {
    path: "/en/knowledge/elearning/*",
    component: _e64760a6,
    name: "knowledge-elearning-all___en"
  }, {
    path: "/th/knowledge/elearning/*",
    component: _e64760a6,
    name: "knowledge-elearning-all___th"
  }, {
    path: "/en/knowledge/podcast/*",
    component: _7d159024,
    name: "knowledge-podcast-all___en"
  }, {
    path: "/th/knowledge/podcast/*",
    component: _7d159024,
    name: "knowledge-podcast-all___th"
  }, {
    path: "/en/knowledge/podcastplaylist/*",
    component: _7772ee40,
    name: "knowledge-podcastplaylist-all___en"
  }, {
    path: "/th/knowledge/podcastplaylist/*",
    component: _7772ee40,
    name: "knowledge-podcastplaylist-all___th"
  }, {
    path: "/en/knowledge/video/*",
    component: _70d106b6,
    name: "knowledge-video-all___en"
  }, {
    path: "/th/knowledge/video/*",
    component: _70d106b6,
    name: "knowledge-video-all___th"
  }, {
    path: "/en/knowledge/videoplaylist/*",
    component: _65fc8bf7,
    name: "knowledge-videoplaylist-all___en"
  }, {
    path: "/th/knowledge/videoplaylist/*",
    component: _65fc8bf7,
    name: "knowledge-videoplaylist-all___th"
  }, {
    path: "/en/glossary/:slug",
    component: _4bfef904,
    name: "glossary-slug___en"
  }, {
    path: "/en/preview-form/:slug?",
    component: _464e20c9,
    name: "preview-form-slug___en"
  }, {
    path: "/th/glossary/:slug",
    component: _4bfef904,
    name: "glossary-slug___th"
  }, {
    path: "/th/preview-form/:slug?",
    component: _464e20c9,
    name: "preview-form-slug___th"
  }, {
    path: "/en/preview/*",
    component: _c8092d7a,
    name: "preview-all___en"
  }, {
    path: "/th/preview/*",
    component: _c8092d7a,
    name: "preview-all___th"
  }, {
    path: "/en/*",
    component: _230c394a,
    name: "all___en"
  }, {
    path: "/th/*",
    component: _230c394a,
    name: "all___th"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
