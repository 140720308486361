const state = () => ({
  submitSuccessMsg: ''
})

const mutations = {
  setFormSubmitSuccessMsg (state, data) {
    state.submitSuccessMsg = data
  }
}

const actions = {
  async submitForm ({ commit }, formData) {
    const config = {
      method: 'post',
      url: process.env.API_ENDPOINT + 'form/submit',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const result = await this.$axios(config)
      .then((res) => {
        return res
      })
      .catch(err => err)
    return result
  },
  async getForm ({ commit }, formUuid) {
    const url = `${process.env.API_ENDPOINT}form/preview/${this.$i18n.locale}/${formUuid}`
    const config = {
      method: 'get',
      url
    }
    const result = await this.$axios(config)
      .then((res) => {
        return res
      })
      .catch(err => err)
    return result
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
