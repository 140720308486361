//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'Picture',
  props: {
    alt: {
      type: String,
      default: () => ''
    },
    sources: {
      type: Array,
      required: true
    },
    lazyImage: {
      type: Boolean,
      default: () => true
    },
    title: {
      type: String,
      default: () => ''
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      index: 0,
      sd: false,
      loadImgError: false,
      youtubeErrorImageSize: 120,
      images: []
    }
  },
  computed: {
    imgSource () {
      // return cloneDeep(this.sources)
      const sources = JSON.parse(JSON.stringify(this.sources))
      return sources || []
    },
    isYoutubeThumbnail () {
      const youtubeImage = this.imgSource[0] || {}
      return !youtubeImage.type
    }
    /* getMdsize () {
      return this.imgSource.filter((r) => {
        return r.size === 'md'
      })
    }, */
    /* images () {
      const src = this.imgSource || []
      if (src.length) {
        let images = src.filter((r) => { return r.size !== 'thumbnail' && r.size })
        // get default image
        const defaultImage = images.filter((r) => { return r.size === 'default' })
        images = images.filter((r) => { return r.size !== 'default' })
        if (!defaultImage[0].type) {
          defaultImage[0].url = defaultImage[0].url.replace('default', 'hqdefault')
        }
        // if youtube type = null
        // if (images.length && defaultImage[0].type) {
        //   for (let i = 0; i < Object.keys(this.$helpers.deviceSizes).length; i++) {
        //     if (!images[i]) {
        //       const size = Object.keys(this.$helpers.deviceSizes)[i]
        //       images.push({
        //         size,
        //         type: images[i - 1].type,
        //         url: images[i - 1].url
        //       })
        //       break
        //     }
        //   }
        // }
        // return [...images.reverse(), defaultImage[0]]
        if (!defaultImage[0].type) {
          return [...images.reverse(), defaultImage[0]]
        }
        return [...images, defaultImage[0]]
      } else {
        return []
      }
    } */
  },
  /* async created () {
      if (this.imgSource[0].type === null && this.getMdsize.length > 0) {
        await this.swapImgSize()
      }
    },
    mounted () {
      if (this.imgSource[0].type === null) {
        this.imgSource[0].url = this.imgSource[1].url
        this.imgSource[4].url = this.imgSource[1].url
        this.index = this.imgSource.length - 2
        this.checkImgYouTube(this.imgSource[this.index].url, this.imgSource[this.index - 1].url)
      }
    }, */
  async created () {
    await this.setImages()
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init () {
      this.handleYoutubeErrorImage()
    },
    setImages () {
      const imgSource = this.imgSource || []
      if (!imgSource.length) { return [] }
      let image = imgSource
      const defaultImage = image.filter((r) => { return r.size === 'default' })
      // image = image.filter((r) => { return /mqdefault.|maxresdefault./i.test(r.url) === false })
      // image = image.filter((r) => { return r.size !== 'thumbnail' && r.size })
      // image = image.filter((r) => { return r.size !== 'default' && r.size })
      image = image.filter((r) => {
        const acceptImage = r.size &&
        (r.size !== 'thumbnail' && r.size !== 'default' && /mqdefault.|maxresdefault./i.test(r.url) === false)
        return acceptImage
      })
      if (this.isYoutubeThumbnail) {
        let defaultName = ''
        const defaultImageUrl = defaultImage[0].url || ''
        if (defaultImageUrl.includes('/default.')) {
          defaultName = 'default'
        } else {
          defaultName = 'sddefault'
        }
        defaultImage[0].url = defaultImageUrl.replace(defaultName, 'hqdefault')
        // console.log('debug', 'replace hqdefault defaultImage[0].url', defaultImage[0].url)
        this.images = [...image.reverse(), defaultImage[0]]
      }
      this.images = [...image, defaultImage[0]]
      // console.log('debug', 'images', JSON.parse(JSON.stringify(this.images)))
    },
    handleYoutubeErrorImage () {
      if (this.isYoutubeThumbnail === true) {
        const itemSrc = { ...this.images[0] }
        this.loadImage(itemSrc.url)
      }
    },
    loadImage (src) {
      const img = new Image()
      img.src = src
      img.onload = () => {
        const isYoutubeImgErr = img.width <= this.youtubeErrorImageSize && this.isYoutubeThumbnail === true
        if (isYoutubeImgErr) {
          this.updateYoutubePicture()
        }
        this.loadImgError = isYoutubeImgErr
      }
    },
    updateYoutubePicture () {
      const pictureEl = this.$refs.pictureEl // document.getElementById(this.pictureId)
      if (!pictureEl) { return }
      const children = pictureEl.children || []
      children.forEach((item) => {
        const { tagName } = item
        if (tagName.toLowerCase() === 'img') {
          const dataSrc = item.dataset.src || ''
          if (dataSrc) {
            const newDataSrc = dataSrc.replace('maxresdefault', 'sddefault')
            item.setAttribute('data-src', newDataSrc)
            item.src = newDataSrc
          }
        } else {
          item.remove()
        }
      })
    }
    /* swapImgSize () {
      const urlFromMd = this.getMdsize[0].url
      for (let i = 0; i < this.imgSource.length - 1; i++) {
        // console.log(this.imgSource[i].url, this.imgSource[i + 1].url)
        // if (i < this.imgSource.length - 1) {
        //   this.imgSource[i].url = this.imgSource[i + 1].url
        // }
        if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
          if (this.imgSource[i].size === 'md' || this.imgSource[i].size === 'lg') {
            this.imgSource[i].url = this.imgSource[i + 1].url
          }
        }
        if (this.imgSource[i].size === 'sm') {
          this.imgSource[i].url = urlFromMd
        }
      }
    },
    checkImgYouTube (maxsrc, minsrc) {
      const img = new Image()
      img.src = maxsrc
      img.onload = () => {
        if (img.width > 120 && this.index >= 2) {
          this.setDefaultImage(maxsrc, minsrc)
        } else if (img.width <= 120) {
          this.replace_srcset(maxsrc, minsrc)
          this.loadImgError = true
        }
      }
    },
    setDefaultImage (maxImg, minImg) {
      this.$nextTick(() => {
        setTimeout(() => {
        // const sd = source
          const element = document.querySelector('div.embed-img > picture > img')
          if (element) {
            const defaultImage = minImg.replace('sddefault', 'hqdefault')
            const elSrcset = document.querySelectorAll('[srcset="' + defaultImage + '"]')
            const newImage = element.src.replace('hqdefault', 'sddefault')
            // console.log('element', element, element.data__src)
            if (element) {
              element.src = newImage
            }
            for (let i = 0; i < elSrcset.length; i++) {
              elSrcset[i].srcset = newImage
            }
          }
        }, 500)
      })
    },
    replace_srcset (target, replacement) {
      this.index -= 1

      if (this.index === 0) {
        // console.log('index', this.index, replacement)
        replacement = require('~/static/images/thumbnail_video_default.jpg')
      }
      const el = document.querySelectorAll('[srcset="' + target + '"]')
      if (el.length) {
        for (let i = 0; i < el.length; i++) {
          el[i].srcset = replacement
        }
      } else {
        const el = document.querySelectorAll('[data-srcset="' + target + '"]')
        for (let i = 0; i < el.length; i++) {
          el[i].dataset.srcset = replacement
        }
      }
      if (this.index > 0) {
        this.checkImgYouTube(this.imgSource[this.index].url, this.imgSource[this.index - 1].url)
      }
    } */
  }
}
