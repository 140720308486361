export default (context, inject) => {
  const { app, store, route, $cookies, localePath } = context
  const { fullPath } = route

  const days = 30
  const hour = 24
  const ms = 1000
  const m = 60
  const s = 60
  const cookiesExpires = new Date(Number(new Date()) + days * ms * s * m * hour)

  const cookieOption = {
    path: '/',
    expires: cookiesExpires,
    domain: process.env.SITE_DOMAIN
  }

  const plugin = {
    addMemberCookie (userProfile) {
      const { accessToken, userRef } = userProfile
      $cookies.set('uref', userRef, cookieOption)
      $cookies.set('id', decodeURIComponent(accessToken), cookieOption)
    },

    removeMemberCookie () {
      $cookies.remove('uref', cookieOption)
      $cookies.remove('id', cookieOption)
    },

    addMemberLocalStorage (userProfile) {
      if (!process.server) {
        const { firstName = '', lastName = '' } = userProfile
        localStorage.setItem('firstName', firstName)
        localStorage.setItem('lastName', lastName)
      }
    },

    removeMemberLocalStorage () {
      if (!process.server) {
        localStorage.removeItem('firstName')
        localStorage.removeItem('lastName')
      }
    },

    login (url, memberLoginUrl, baseURL) {
      const _setMemberLoginUrl = memberLoginUrl || process.env.API_LOGIN + '&language=' + app.i18n.locale
      const _baseUrl = baseURL || process.env.WEBSITE_URL
      if (url) {
        const newUrl = `${_setMemberLoginUrl}&redirectUrl=${_baseUrl}${url}`
        return newUrl
        // redirect(url)
      } else {
        const newUrl = `${_setMemberLoginUrl}&redirectUrl=${encodeURIComponent(`${_baseUrl}${fullPath}`)}`
        // const redirectUrl = `${this.$i18n.locale}/about/event-calendar/event/eventdetails${encodeURIComponent(`?id=${this.id}`)}`
        return newUrl
        // redirect(redirectUrl)
      }
    },

    // singleSignOn (isLogin, url, baseURL, isMemberURL) {
    //   // const accessToken = $cookies.get('id') || ''
    //   const _baseUrl = baseURL || process.env.SETTRADE_WEBSITE_URL
    //   const memberLoginUrl = process.env.SETTRADE_MEMBER_LOGIN_URL
    //   if (isLogin) {
    //     return `${_baseUrl}${app.i18n.locale}/authorization?redirectUrl=${_baseUrl}${url}`
    //   } else {
    //     return isMemberURL ? this.login(url, memberLoginUrl, _baseUrl) : `${_baseUrl}` + url
    //   }
    // },

    // regisSetMember () {
    //   const setMemberRegisUrl = process.env.SET_MEMBER_REGIS_URL
    //   const redirectUrl = `${setMemberRegisUrl}`
    //   return redirectUrl
    // },

    async authCode (code) {
      let token = ''
      if (code) {
        token = await store.dispatch('auth/requestToken', code)
      }
      await this.authUser(token)
    },

    async authUser (token) {
      const accessToken = token || $cookies.get('id') || ''
      if (accessToken) {
        const validUser = await store.dispatch('auth/authUser', accessToken)
        if (validUser === true) {
          const userProfile = await store.dispatch(
            'auth/getUserProfile',
            accessToken
          )
          this.addMemberCookie({ ...userProfile, accessToken })
          this.addMemberLocalStorage(userProfile)
          this.saveUserData(accessToken)
        } else {
          this.removeMemberCookie()
          this.removeMemberLocalStorage()
        }
      }
    },

    userProfile () {
      if (!process.server) {
        const userRef = $cookies.get('uref') || ''
        const firstName = localStorage.getItem('firstName')
        const lastName = localStorage.getItem('lastName')
        return { userRef, firstName, lastName }
      } else {
        return { userRef: '', firstName: '', lastName: '' }
      }
    },

    async logout (token) {
      const accessToken = token || $cookies.get('id') || ''
      await store.dispatch('auth/logOut', accessToken)
      this.removeMemberCookie()
      this.removeMemberLocalStorage()
      const route = fullPath.replace(`/${app.i18n.locale}`, '')
      if (route.startsWith('/member')) {
        // redirect(localePath({ name: 'home' }))
        window.location.href = localePath({ name: 'home' })
      } else {
        window.location.reload()
      }
    },

    userRef () {
      const userRef = $cookies.get('uref') || ''
      const accessToken = $cookies.get('id') || ''
      if (accessToken && userRef) {
        return userRef
      }
      return ''
    },

    permissionUser () {
      return !!this.userRef()
    },

    async isLogin () {
      const accessToken = $cookies.get('id') || ''
      const validUser = await store.dispatch('auth/authUser', accessToken)
      return validUser
    },
    async saveUserData (accessToken) {
      const userData = await store.dispatch('profile/getUserStatus', accessToken)
      // CMS API - save user set member
      await store.dispatch('profile/addUser', {
        ...userData,
        token: accessToken
      })
    }
  }

  inject('authUser', plugin)
  context.$authUser = plugin
}
