import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=792a65d2&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterBanner: require('/usr/src/set-nuxt/components/Banner/FooterBanner.vue').default,ButtonScrollToTop: require('/usr/src/set-nuxt/components/Button/ButtonScrollToTop.vue').default,NavbarMenu: require('/usr/src/set-nuxt/components/Navbar/NavbarMenu.vue').default,Sitemap: require('/usr/src/set-nuxt/components/Sitemap/Sitemap.vue').default,ContactCenter: require('/usr/src/set-nuxt/components/ContactCenter/ContactCenter.vue').default,WebSiteSocial: require('/usr/src/set-nuxt/components/WebSiteSocial/WebSiteSocial.vue').default,Logo: require('/usr/src/set-nuxt/components/Logo/Logo.vue').default,Footer: require('/usr/src/set-nuxt/components/Footer/Footer.vue').default})
