// import Vue from 'vue'
import moment from 'moment'

// export plugin
export default (context, inject) => {
  const helpers = {
    /**
     * Page Type
     */
    pageType: {
      EBook: 'EBookDetail'
    },
    /**
     * Device screen sizes
     */
    deviceSizes: {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400
    },
    /**
     * Responsive image sizes
     */
    respImageSizes: {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1240,
      xxl: 1920
    },
    /**
     * handle error image
     */
    handleImage (cfg) {
      if (!cfg.img) {
        return
      }
      let src = ''
      if (typeof cfg.img === 'string') {
        src = cfg.img || ''
      } else {
        src = cfg.img.src || ''
      }
      if (!src) {
        return
      }
      // custom events
      const events = {
        onload: 'onLoad',
        onerror: 'onError'
      }

      // new image class
      const image = new Image()
      image.src = src
      image.onload = function () {
        if (typeof cfg[events.onload] === 'function') {
          cfg[events.onload](cfg.img, {
            height: image.height,
            width: image.width
          })
        }
      }
      image.onerror = function (e) {
        if (typeof cfg[events.onerror] === 'function') {
          cfg[events.onerror](cfg.img, e)
        }
      }
    },
    /**
     *  Get domain from url https://www.example.com/* --> www.example.com
     */
    getDomainFromURL (url) {
      if (!url) {
        return ''
      }
      const d = (new URL(url))
      return d.hostname || ''
    },
    /**
     *  Date format
     */
    checkDateEventNoZero (startDate, endDate) {
      const isSameDate = moment(startDate).isSame(endDate, 'day')
      return isSameDate
        ? moment(startDate).format('D')
        : moment(startDate).format('D') + '-' + moment(endDate).format('D')
    },
    checkDateEvent (startDate, endDate) {
      const isSameDate = moment(startDate).isSame(endDate, 'day')
      return isSameDate
        ? moment(startDate).format('DD')
        : moment(startDate).format('DD') + '-' + moment(endDate).format('DD')
    },
    checkMonthYearEvent (startDate, endDate) {
      const isSameMonth = moment(startDate).isSame(endDate, 'month')
      const isSameYear = moment(startDate).isSame(endDate, 'year')
      if (isSameYear) {
        if (isSameMonth) {
          return moment(startDate).format('MMM') +
          ' ' +
          moment(endDate)
            .add(543, 'year')
            .format('YY')
        } else {
          return moment(startDate).format('MMM') +
          ' - ' + moment(endDate).format('MMM') +
          ' ' +
          moment(endDate)
            .add(543, 'year')
            .format('YY')
        }
      } else {
        return moment(startDate).format('MMM') +
          ' ' +
          moment(startDate)
            .add(543, 'year')
            .format('YY') +
          '-' +
          moment(endDate).format('MMM') +
          ' ' +
          moment(endDate)
            .add(543, 'year')
            .format('YY')
      }
    },
    checkDateTimeEvent (startDate, endDate) {
      const isSameDate = moment(startDate).isSame(endDate, 'day')
      const isSameMonth = moment(startDate).isSame(endDate, 'month')
      const isSameYear = moment(startDate).isSame(endDate, 'year')
      return isSameMonth && isSameYear && isSameDate
        ? moment(startDate).format('DD') +
            ' ' +
            moment(startDate).format('MMM') +
            ' ' +
            moment(startDate)
              .add(543, 'year')
              .format('YY') +
            ' เวลา ' +
            moment(startDate).format('LT') +
            '-' +
            moment(endDate).format('LT') +
            ' น.'
        : moment(startDate).format('DD') +
            ' ' +
            moment(startDate).format('MMM') +
            ' ' +
            moment(startDate)
              .add(543, 'year')
              .format('YY') +
            ' - ' +
            moment(endDate).format('DD') +
            ' ' +
            moment(endDate).format('MMM') +
            ' ' +
            moment(endDate)
              .add(543, 'year')
              .format('YY') +
            ' เวลา ' +
            moment(startDate).format('LT') +
            '-' +
            moment(endDate).format('LT') +
            ' น.'
    },

    checkContentType (type) {
      const ContentOtherCate = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                fill="currentColor"
                d="M15 0C6.716 0 0 6.716 0 15c0 8.286 6.716 15 15 15 8.286 0 15-6.714 15-15 0-8.284-6.714-15-15-15zm0 29.011C7.269 29.011.978 22.725.978 15S7.268.989 15 .989C22.731.989 29.022 7.275 29.022 15S22.732 29.011 15 29.011z"
                transform="translate(-610 -246) translate(610 246)"
              />
              <g fill="currentColor">
                <path
                  d="M12.4 1.72l3.032 3.293v11.329c0 .548-.416 1-.949 1.059l-.116.006H3.659c-.548 0-1.001-.416-1.059-.95l-.006-.115V2.785c0-.548.416-1.001.949-1.059l.116-.006H12.4zm-.643.895H3.66c-.076 0-.14.049-.162.116l-.009.054v13.557c0 .075.05.139.117.162l.054.008h10.708c.075 0 .14-.049.162-.117l.009-.053V5.652h-2.333c-.22 0-.403-.158-.44-.367l-.008-.08v-2.59zM8.598 0v.895H1.065c-.075 0-.14.05-.162.117l-.009.054v13.557c0 .075.05.139.117.162l.054.009.654-.001v.894l-.654.001c-.548 0-1-.416-1.059-.95L0 14.624V1.066C0 .518.416.066.95.007l.115-.006L8.598 0zm4.076 12.375c.31 0 .56.251.56.56 0 .31-.25.562-.56.562h-7.29c-.31 0-.561-.251-.561-.561 0-.31.251-.561.56-.561h7.291zm0-2.804c.31 0 .56.251.56.56 0 .31-.25.562-.56.562h-7.29c-.31 0-.561-.251-.561-.561 0-.31.251-.561.56-.561h7.291zm0-2.804c.31 0 .56.251.56.56 0 .31-.25.562-.56.562h-7.29c-.31 0-.561-.251-.561-.561 0-.31.251-.561.56-.561h7.291zm-.022-3.453v1.444h1.329l-1.329-1.444z"
                  transform="translate(-610 -246) translate(610 246) translate(7.742 5.806)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>`
      const Ebook = `<svg
    v-if="contentType === 'EBookDetail' || contentType === 'Ebook'"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g fill="none" fill-rule="evenodd">
      <g fill-rule="nonzero">
        <g>
          <g fill="currentColor">
            <g>
              <path
                d="M15 0C6.716 0 0 6.716 0 15c0 8.286 6.716 15 15 15 8.286 0 15-6.714 15-15 0-8.284-6.714-15-15-15zm0 29.011C7.269 29.011.978 22.725.978 15S7.268.989 15 .989C22.731.989 29.022 7.275 29.022 15S22.732 29.011 15 29.011z"
                transform="translate(-313 -246) translate(313 246)"
              />
            </g>
          </g>
          <g fill="currentColor">
            <path
              d="M11.976 0c.3 0 .544.244.544.544v12.52c0 .271-.197.496-.456.538l-.088.007H2.45c-.75 0-1.361.61-1.361 1.36 0 .751.61 1.362 1.36 1.362h9.527c.3 0 .544.243.544.544 0 .3-.244.544-.544.544H2.45C1.099 17.42 0 16.32 0 14.97V2.246C0 1.007 1.007 0 2.245 0h9.73zm0 14.425c.3 0 .544.244.544.545 0 .3-.244.544-.544.544H2.45c-.301 0-.545-.244-.545-.544 0-.3.244-.545.545-.545h9.526zM2.449 1.088h-.204c-.637 0-1.156.52-1.156 1.157l-.001 10.69c.39-.262.858-.415 1.362-.415L2.449 1.088zm8.982 0H3.538V12.52h7.893V1.089z"
              transform="translate(-313 -246) translate(313 246) translate(8.71 6.774)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>`
      const Article = `
  <svg
    v-if="contentType === 'ArticleDetail' || contentType === 'Article'"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                fill="currentColor"
                fill-rule="nonzero"
                d="M15 0C6.716 0 0 6.716 0 15c0 8.286 6.716 15 15 15 8.286 0 15-6.714 15-15 0-8.284-6.714-15-15-15zm0 29.011C7.269 29.011.978 22.725.978 15S7.268.989 15 .989C22.731.989 29.022 7.275 29.022 15S22.732 29.011 15 29.011z"
                transform="translate(-254 -246) translate(254 246)"
              />
              <g fill="currentColor">
                <path
                  d="M6.32 15.943c.573.333 1.232.509 1.906.509.673 0 1.332-.176 1.905-.509.528-.306.946-.455 1.279-.455h5.042V1.928h-1.928V0h-.482C11.824 0 9.7 1.177 8.146 2.758c-.943-.544-2.008-.83-3.104-.83H0v13.56h5.042c.332 0 .75.149 1.278.455zm8.204-4.311v-8.74h.964v9.704H11.41c-.243 0-.485.015-.724.042 1.091-.648 2.256-1.006 3.356-1.006h.482zM8.708 3.563c1.407-1.487 3.18-2.423 4.852-2.577v9.7c-1.8.135-3.502 1.023-4.852 2.208v-9.33zm-3.666-.671c.957 0 1.885.258 2.702.748v9.573c-.84-.405-1.76-.617-2.702-.617H.964V2.892h4.078zM.964 13.56h4.078c1.042 0 2.05.305 2.916.883l.268.178.267-.178c.867-.578 1.875-.883 2.917-.883h4.078v.964H11.41c-.514 0-1.074.186-1.763.585-.426.248-.917.379-1.421.379s-.995-.131-1.422-.379c-.688-.4-1.248-.585-1.762-.585H.964v-.964z"
                  transform="translate(-254 -246) translate(254 246) translate(6.774 6.774)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>`

      const Media = `
  <svg
    v-if="contentType === 'Media'"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g fill="currentColor" fill-rule="nonzero">
            <g>
              <path
                d="M15 0C6.716 0 0 6.716 0 15c0 8.286 6.716 15 15 15 8.286 0 15-6.714 15-15 0-8.284-6.714-15-15-15zm0 29.011C7.269 29.011.978 22.725.978 15S7.268.989 15 .989C22.731.989 29.022 7.275 29.022 15S22.732 29.011 15 29.011z"
                transform="translate(-491 -246) translate(491 246)"
              />
            </g>
          </g>
          <g fill="currentColor">
            <path
              d="M9.154 0c.142 0 .28.053.38.148l4.308 4.038c.096.09.158.218.158.357v11.173c0 .835-.725 1.515-1.615 1.515H1.615C.725 17.23 0 16.55 0 15.716V1.514C0 .68.725 0 1.615 0zm-.539 1.01h-7c-.297 0-.538.226-.538.504v14.202c0 .279.241.505.538.505h10.77c.297 0 .538-.226.538-.505V5.048h-2.692c-.891 0-1.616-.68-1.616-1.514V1.01zM7.603 5.298c.22 0 .397.167.397.373v4.71h1.59c.156 0 .297.085.361.219.065.133.04.29-.062.399l-2.782 2.98c-.075.08-.184.128-.299.128-.114 0-.223-.047-.299-.128L3.728 11c-.103-.11-.128-.266-.063-.4.064-.132.206-.218.362-.218h1.59v-4.71c0-.206.177-.373.396-.373h1.59zm2.09-3.574v1.81c0 .278.24.504.538.504h1.93L9.693 1.724z"
              transform="translate(-491 -246) translate(491 246) translate(8 6)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>`
      const Elearning = `
  <svg
    v-if="contentType === 'VideoDetail' || contentType === 'Video'"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="currentColor"
              d="M15 0C6.716 0 0 6.716 0 15c0 8.286 6.716 15 15 15 8.286 0 15-6.714 15-15 0-8.284-6.714-15-15-15zm0 29.011C7.269 29.011.978 22.725.978 15S7.268.989 15 .989C22.731.989 29.022 7.275 29.022 15S22.732 29.011 15 29.011z"
              transform="translate(-135 -246) translate(135 246)"
            />
            <path
              fill="currentColor"
              d="M21.004 14.19l-8.295-6.31c-.21-.16-.488-.183-.72-.058-.23.124-.376.375-.376.648v12.62c0 .274.145.524.376.648.1.054.208.08.315.08.143 0 .285-.046.405-.138l8.295-6.31c.18-.137.286-.356.286-.59 0-.234-.106-.453-.286-.59zm-8.009 5.476V9.894l6.423 4.886-6.423 4.886z"
              transform="translate(-135 -246) translate(135 246)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>`
      const Video = `
  <svg
    v-if="contentType === 'VideoDetail' || contentType === 'Video'"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="currentColor"
              d="M15 0C6.716 0 0 6.716 0 15c0 8.286 6.716 15 15 15 8.286 0 15-6.714 15-15 0-8.284-6.714-15-15-15zm0 29.011C7.269 29.011.978 22.725.978 15S7.268.989 15 .989C22.731.989 29.022 7.275 29.022 15S22.732 29.011 15 29.011z"
              transform="translate(-135 -246) translate(135 246)"
            />
            <path
              fill="currentColor"
              d="M21.004 14.19l-8.295-6.31c-.21-.16-.488-.183-.72-.058-.23.124-.376.375-.376.648v12.62c0 .274.145.524.376.648.1.054.208.08.315.08.143 0 .285-.046.405-.138l8.295-6.31c.18-.137.286-.356.286-.59 0-.234-.106-.453-.286-.59zm-8.009 5.476V9.894l6.423 4.886-6.423 4.886z"
              transform="translate(-135 -246) translate(135 246)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>`
      switch (type) {
        case 'ContentDetail':
        case 'ContentCategory':
        case 'OtherPage':
          return ContentOtherCate
        case 'ArticleDetail':
        case 'Article':
          return Article
        case 'Media':
          return Media
        case 'VideoDetail':
        case 'Video':
          return Video
        case 'EBookDetail':
        case 'Ebook':
          return Ebook
        case 'ELearning':
          return Elearning
      }
      return ContentOtherCate
    },

    /**
     * Number
     */
    numberWithCommas (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    /**
     * UUID
     */
    uuid () {
      let dt = new Date().getTime()
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
      return uuid
    },
    /**
     * clientId
     */
    async setClientUuid () {
      // store client id
      if (process.server) {
        const clientId = context.$cookies.get('clientUuid')
        const already = clientId ? true : false
        const uuid = already ? clientId : await this.uuid()
        await context.$cookies.set('clientUuid', uuid, {
          httpOnly: true,
          secure: process.env.NODE_ENV === 'production', // secure equal true on production
          path: '/',
          maxAge: 60 * 60 * 24 * 30 // month
        })
        await context.store.commit('utility/setClientId', uuid)
      }
    },

    /**
     * Get content error handler
     * @param {*} error
     * @param {*} redirect
     */
    getContentErrorHandler (error, redirect) {
      const { response } = error
      if (response) {
        // eslint-disable-next-line no-console
        console.error('status', response.status)
        // eslint-disable-next-line no-console
        console.error('headers', response.headers)
        // eslint-disable-next-line no-console
        console.error('data', response.data || {})
      }
      if (response && response.status === 301) {
        redirect(response.data)
      } else if (response && response.status === 404) {
        redirect('/th/error/404')
      } else if (response && response.data && response.status === 500) {
        redirect('/th/error')
      } else {
        redirect('/th/error/503')
      }
    }
  }
  inject('helpers', helpers)
  context.$helpers = helpers
}
// const plugin = {
//   install () {
//     Vue.prototype.$helpers = helpers
//   }
// }
// Vue.use(plugin)
