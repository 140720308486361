
export default {
  name: 'RawHtml',
  props: {
    // for event tracking
    evtTrackingElement: {
      type: String,
      default: ''
    },
    evtTrackingAction: {
      type: String,
      default: ''
    },
    evtTrackingTeamName: {
      type: String,
      default: ''
    },
    html: {
      type: String,
      default: () => ''
    }
  },
  // serverCacheKey: props => 'RawHtml::' + `${props.html}`,
  mounted () {
    this.registerEvents()
  },
  beforeDestroy () {
    this.removeEvents()
  },
  methods: {
    registerEvents () {
      const element = this.$el
      const tagA = element.querySelectorAll('a')
      tagA.forEach((elA) => {
        elA.addEventListener('click', this.onClickTracking)
      })
    },
    removeEvents () {
      const element = this.$el
      const tagA = element.querySelectorAll('a')
      tagA.forEach((elA) => {
        elA.removeEventListener('click', this.onClickTracking)
      })
    },
    onClickTracking (event) {
      event.stopPropagation()
      const tagA = event.currentTarget || event.target
      const data = tagA.dataset || {}
      this.handleClickTracking(data)
    },
    handleClickTracking (data) {
      const eleData = data || {}
      const {
        eventtracking,
        eventtrackingAction,
        eventtrackingLabel
        // eventtrackingTeam,
        // eventtrackingTeamName
      } = eleData
      // const dataTeam = this.teams || []
      const isEtk = eventtracking === 'true'
      const dataTeamName = this.evtTrackingTeamName // eventtrackingTeamName || this.findEventTrackingTeam(dataTeam, eventtrackingTeam) // Data Category(Team)
      const dataLabel = eventtrackingLabel // Data Label
      let dataAction = eventtrackingAction // Data Action(Section)
      if (this.actionTracking) {
        dataAction = `${this.evtTrackingAction} ${eventtrackingAction}`
      }
      if (isEtk) {
        this.clickTracking({
          element: this.evtTrackingElement,
          title: dataLabel,
          sectionName: dataAction,
          teamName: dataTeamName
        })
      }
    },
    findEventTrackingTeam (data, uuid) {
      const team = data.filter((item) => { return item.uuid === uuid })
      if (team.length) {
        return team[0].name
      }
      return ''
    }
  },
  render (h) {
    return h('div', {
      class: 'raw-html',
      // on: {
      //   click: this.onClickTracking
      // },
      domProps: {
        innerHTML: decodeURI(this.html)
      }
    })
  }
}
