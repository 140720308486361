import Vue from 'vue'

Vue.mixin({
  methods: {
    getContentMenu (locationName) {
      if (this.content && this.content.menuLocations) {
        const menu = this.content.menuLocations.filter((r) => { return r.locationName === locationName }) || []
        if (menu.length) {
          return menu[0].menu
        }
      }
      return {}
    }
  }
})
