
const state = () => ({
  clientUuid: '',
  limit_call_service: {
    element_activity_ads: 0,
    element_activity_calendar: 0,
    element_follow_group: 0,
    element_relate_article: 0,
    element_recommendation: 0,
    element_bookmark: 0
  }
})

const mutations = {
  /*
   ** set Limit call service
   */
  // **** Activity_Ads ****
  setLCS_ActivityAds (state, count) {
    state.limit_call_service.element_activity_ads = count
  },
  // **** Activity_Calendar ****
  setLCS_ActivityCalendar (state, count) {
    state.limit_call_service.element_activity_calendar = count
  },
  // **** FollowGroup ****
  setLCS_FollowGroup (state, count) {
    state.limit_call_service.element_follow_group = count
  },
  // **** RelateArticle ****
  setLCS_RelateArticle (state, count) {
    state.limit_call_service.element_relate_article = count
  },
  // **** Recommendation ****
  setLCS_Recommendation (state, count) {
    state.limit_call_service.element_recommendation = count
  },
  // **** BookMark ****
  setLCS_BookMark (state, count) {
    state.limit_call_service.element_bookmark = count
  },

  /* set client uuid */
  setClientId (state, clientUuid) {
    state.clientUuid = clientUuid
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
