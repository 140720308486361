import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6d18bc7c&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalUnFollow: require('/usr/src/set-nuxt/components/Modals/ModalUnFollow.vue').default,ModalUnBookmark: require('/usr/src/set-nuxt/components/Modals/ModalUnBookmark.vue').default,LazyModalFollow: function () { return import('/usr/src/set-nuxt/components/Modals/ModalFollow.vue' /* webpackChunkName: "components/modal-follow" */).then(function(m) { return m['default'] || m }) },Header: require('/usr/src/set-nuxt/components/Header/Header.vue').default,Footer: require('/usr/src/set-nuxt/components/Footer/Footer.vue').default,Lightbox: require('/usr/src/set-nuxt/components/Personalized/Lightbox.vue').default,ChatBotZwiz: require('/usr/src/set-nuxt/components/ChatBotZwiz/ChatBotZwiz.vue').default,Pdpa: require('/usr/src/set-nuxt/components/Pdpa/Pdpa.vue').default})
