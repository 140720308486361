//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Footer',
  // serverCacheKey: 'Layout::Footer',
  computed: {
    content () {
      return this.$store.state.contents.content || {}
    },
    footerMenu () {
      return this.getContentMenu('Footer Menu')
    },
    sitemap () {
      return this.getContentMenu('Sitemap')
    },
    websiteInfo () {
      return this.content.websiteInfo || {}
    }
  }
}
