const state = () => ({
  handle: {
    active: true,
    type: '',
    follow: [],
    index: 0,
    bookmark: '',
    callback: null
  },
  isBooking: {
    pageUuid: '',
    status: null,
    index: 0,
    pageUuidArray: []
  },
  isBookmarked: false,
  isFollowed: false,
  bookmarkCount: 0,
  followCount: 0,
  followBookmarkFailedCount: 0,
  isFollowing: {
    pageUuid: [],
    index: 0
  }
})

const mutations = {
  setActive (state, data) {
    state.handle.active = data
  },
  setType (state, data) {
    state.handle.type = data
  },
  setIndex (state, data) {
    state.handle.index = data
  },
  setFollow (state, data) {
    state.handle.follow = data
  },
  setBookmark (state, data) {
    state.handle.bookmark = data
  },
  setCallback (state, data) {
    if (typeof data === 'function') {
      state.handle.callback = data
    }
  },
  setBookmarkStatus (state, data) {
    state.isBookmarked = null
    state.isBookmarked = data
  },
  setFollowStatus (state, data) {
    state.isFollowed = null
    state.isFollowed = data
  },
  setIsBooking (state, data) {
    state.isBooking.pageUuid = data.pageUuid
    state.isBooking.index = data.index
    state.isBooking.status = data.status
    state.isBooking.pageUuidArray = data.pageUuidArray
  },
  setIsFollowing (state, data) {
    state.isFollowing.pageUuid = data.pageUuid
    state.isFollowing.index = data.index
  },
  setCountBookmark (state, data) {
    state.bookmarkCount = data
  },
  setCountFollow (state, data) {
    state.followCount = data
  },
  setFollowBookmarkFailedCount (state, data) {
    state.followBookmarkFailedCount = data
  }
}

const actions = {

  async saveBookMark ({ commit }, data) {
    const result = await this.$axios
      .post(process.env.API_ENDPOINT + 'setmember/savebookmark', data)
      .then((res) => {
        return res.data
      })
      .catch(err => err)

    return result
  },

  async saveBookMarkExternalPage ({ commit }, pageUuid) {
    const result = await this.$axios
      .get(process.env.API_ENDPOINT + 'setmember/bookmarkexternalpage', { params: { pageUuid } })
      .then((res) => {
        return res
      }).catch(err => err)
    return result
  },

  async saveBookMarkFile ({ commit }, mediaUuid) {
    const result = await this.$axios
      .get(process.env.API_ENDPOINT + 'setmember/bookmarkmedia', { params: { mediaUuid } })
      .then((res) => {
        return res
      }).catch(err => err)

    return result
  },

  async saveFollow ({ commit }, data) {
    const result = await this.$axios
      .post(process.env.API_ENDPOINT + 'setmember/Savefollowgroup', data)
      .then((res) => {
        return res.data
      })
      .catch(err => err)
    return result
  },

  setIsBookmarked ({ commit }, data) {
    commit('setBookmarkStatus', data)
  },

  setIsFollowed ({ commit }, data) {
    commit('setFollowStatus', data)
  },

  addBookmarkCount ({ commit }, data) {
    commit('setCountBookmark', data)
  },

  addFollowCount ({ commit }, data) {
    commit('setCountFollow', data)
  },

  addFollowBookmarkFailedCount ({ commit }, data) {
    commit('setFollowBookmarkFailedCount', data)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
