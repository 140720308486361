const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['authorization'] = require('../middleware/authorization.js')
middleware['authorization'] = middleware['authorization'].default || middleware['authorization']

middleware['content'] = require('../middleware/content.js')
middleware['content'] = middleware['content'].default || middleware['content']

export default middleware
