/**
 * State
 */
const state = () => ({
  userProfile: {
    firstName: null,
    lastName: null,
    userRef: null
  }
})

/**
 * Mutations
 */
const mutations = {
  setUserProfile (state, data) {
    state.userProfile = data
  }
}

/**
 * Actions
 */
const actions = {
  async requestToken ({ commit }, code) {
    try {
      const response = await this.$apiMember.get('/api/gw-api/member/request-token', {
        params: { code }
      })
      if (response.status === 200) {
        return response.data.token
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  },

  async authUser ({ commit }, token) {
    try {
      if (token) {
        this.$apiMember.defaults.headers.common.authorization = `bearer ${token}`
      }
      const response = await this.$apiMember.get('/api/gw-api/member/authorization')
      if (response.status === 403) {
        return false
      } else {
        return true
      }
    } catch (e) {
      return false
    }
  },

  async getUserProfile ({ commit }, token) {
    try {
      if (token) {
        this.$apiMember.defaults.headers.common.authorization = `bearer ${token}`
        const userProfile = await this.$apiMember.$get('/api/gw-api/member/profile')
        commit('setUserProfile', userProfile)
        return userProfile
      }
    } catch (e) {
      commit('setUserProfile', {
        firstName: null,
        lastName: null,
        userRef: null
      })
      return null
    }
  },

  async logOut ({ commit }, token) {
    try {
      if (token) {
        this.$apiMember.defaults.headers.common.authorization = `bearer ${token}`
        await this.$apiMember.$get('/api/gw-api/member/logout')
        commit('setUserProfile', {
          firstName: null,
          lastName: null,
          userRef: null
        })
      }
    } catch (e) {
      commit('setUserProfile', {
        firstName: null,
        lastName: null,
        userRef: null
      })
      return null
    }
  }
}

const getters = {
  getUserProfile: (state) => {
    return state.userProfile
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
